/**
 * Utility functions for asset handling
 */

/**
 * Gets the correct asset path based on whether in module mode
 * @param {string} path Original asset path
 * @param {string|null} basePath Base path in module mode
 * @returns {string} The correct path to use
 */
export const getAssetPath = (path, basePath) => {
  if (!basePath) return path;
  
  // Strip leading slash if present to avoid double slashes
  const cleanPath = path.startsWith('/') ? path.substring(1) : path;
  return `${basePath}/${cleanPath}`;
};
