// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOTTzv4OjRzIA2OitfITzF_pkBjTj4wzg",
  authDomain: "medi-3fea1.firebaseapp.com",
  projectId: "medi-3fea1",
  storageBucket: "medi-3fea1.appspot.com",
  messagingSenderId: "473204528941",
  appId: "1:473204528941:web:75de034f8ebc0f180025a4",
  measurementId: "G-5Z45W9Z0LN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, db, storage };
