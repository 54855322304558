import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import ProfileSetup from '../ProfileSetup';
import styles from './LoginModal.module.css';

const LoginModal = ({ isOpen, onClose }) => {
  const { validateResearchKey, signInWithEmail } = useAuth();
  
  // State for tab navigation
  const [activeTab, setActiveTab] = useState('login'); // 'login', 'researchKey', or 'requestAccess'
  
  // Form states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [researchKey, setResearchKey] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Additional profile fields for request access
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [hospital, setHospital] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [interest, setInterest] = useState('');
  
  // Result states
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Research key validation result
  const [validKeyData, setValidKeyData] = useState(null);
  const [showProfileSetup, setShowProfileSetup] = useState(false);
  
  // Reset state when modal is opened/closed
  useEffect(() => {
    if (isOpen) {
      setError('');
      setSuccess('');
      setValidKeyData(null);
      setShowProfileSetup(false);
    }
  }, [isOpen]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);
    
    try {
      if (activeTab === 'requestAccess') {
        // Create access request data in Firebase Firestore
        const requestData = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber,
          role,
          hospital,
          specialty,
          interest,
          submitted_at: new Date().toISOString(),
          status: 'pending'
        };
        
        // Save to Firestore access_requests collection
        const { db } = await import('../../../services/firebase');
        const { collection, addDoc } = await import('firebase/firestore');
        
        await addDoc(collection(db, 'access_requests'), requestData);
        
        // Display confirmation message after successful request
        setSuccess(`Thank you for your interest! Your access request has been submitted. We'll contact you at ${email} once your request is processed.`);
        
        // Reset form fields after successful request
        setEmail('');
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setRole('');
        setHospital('');
        setSpecialty('');
        setInterest('');
      } else if (activeTab === 'login') {
        const { user, error } = await signInWithEmail(email, password);
        
        if (error) {
          // Handle Firebase auth errors
          let errorMessage = 'Failed to sign in. Please try again.';
          
          if (error.code === 'auth/invalid-credential' || error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            errorMessage = 'Invalid email or password. Please try again.';
          } else if (error.code === 'auth/too-many-requests') {
            errorMessage = 'Too many failed login attempts. Please try again later or reset your password.';
          } else if (error.code === 'auth/user-disabled') {
            errorMessage = 'This account has been disabled. Please contact support.';
          } else if (error.code === 'auth/network-request-failed') {
            errorMessage = 'Network error. Please check your internet connection.';
          }
          
          throw new Error(errorMessage);
        }
        
        onClose(); // Close the modal after successful login
      } else if (activeTab === 'researchKey') {
        // Validate research key
        const { success, error, keyData } = await validateResearchKey(researchKey);
        
        if (error) {
          throw new Error(error || 'Invalid research key');
        }
        
        if (success && keyData) {
          // Store valid key data for profile setup
          setValidKeyData(keyData);
          
          // Show profile setup form
          setShowProfileSetup(true);
        }
      }
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle profile setup completion
  const handleProfileSetupComplete = (profile) => {
    setShowProfileSetup(false);
    setSuccess(`Welcome, ${profile.displayName}! Your account has been created.`);
    
    // Close modal after short delay
    setTimeout(() => {
      onClose();
      window.location.reload(); // Refresh to update UI with logged in state
    }, 2000);
  };
  
  // If showing profile setup, render that instead of the login tabs
  if (showProfileSetup && validKeyData) {
    return (
      <div className={styles["login-modal-overlay"]}>
        <div className={styles["login-modal"]}>
          <button
            type="button"
            className={styles["close-button"]}
            onClick={onClose}
            aria-label="Close"
          >
            ×
          </button>
          <ProfileSetup 
            keyData={validKeyData} 
            onComplete={handleProfileSetupComplete}
            isResearchKey={true}
          />
        </div>
      </div>
    );
  }
  
  // Render login modal
  if (!isOpen) return null;
  
  return (
    <div className={styles["login-modal-overlay"]}>
      <div className={styles["login-modal"]}>
        <button className={styles["close-button"]} onClick={onClose}>×</button>
        
        <h2>Welcome to Project Moose</h2>
        
        <div className={styles["login-tabs"]}>
          <button 
            className={`${styles["tab-button"]} ${activeTab === 'login' ? styles.active : ''}`}
            onClick={() => setActiveTab('login')}
          >
            Sign In
          </button>
          <button 
            className={`${styles["tab-button"]} ${activeTab === 'researchKey' ? styles.active : ''}`}
            onClick={() => setActiveTab('researchKey')}
          >
            Research Key
          </button>
          <button 
            className={`${styles["tab-button"]} ${activeTab === 'requestAccess' ? styles.active : ''}`}
            onClick={() => setActiveTab('requestAccess')}
          >
            Request Access
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className={styles["login-form"]}>
          {error && <div className={styles["error-message"]}>{error}</div>}
          {success && <div className={styles["success-message"]}>{success}</div>}
          
          {activeTab === 'researchKey' ? (
            <div className={styles["form-group"]}>
              <label htmlFor="researchKey">Research Key</label>
              <input
                id="researchKey"
                type="text"
                value={researchKey}
                onChange={(e) => setResearchKey(e.target.value)}
                placeholder="Enter your research key"
                required
              />
              <p className={styles["form-help-text"]}>
                Enter the research key provided by your institution or administrator.
              </p>
            </div>
          ) : activeTab === 'requestAccess' ? (
            <>
              <div className={styles["profile-fields"]}>
                <h3>Request Access</h3>
                <p className={styles["form-help-text"]}>Fill out the form below to request access to Project Moose.</p>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="firstName">First Name *</label>
                  <input
                    id="firstName"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="lastName">Last Name *</label>
                  <input
                    id="lastName"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="email">Email *</label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    id="phoneNumber"
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Optional"
                  />
                </div>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="role">Role *</label>
                  <select
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                  >
                    <option value="">Select your role</option>
                    <option value="doctor">Medical Doctor (MD)</option>
                    <option value="nurse">Nurse</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="hospital">Hospital/Institution</label>
                  <input
                    id="hospital"
                    type="text"
                    value={hospital}
                    onChange={(e) => setHospital(e.target.value)}
                    placeholder="Optional"
                  />
                </div>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="specialty">Specialty</label>
                  <input
                    id="specialty"
                    type="text"
                    value={specialty}
                    onChange={(e) => setSpecialty(e.target.value)}
                    placeholder="e.g., Internal Medicine, Cardiology, etc. (Optional)"
                  />
                </div>
                
                <div className={styles["form-group"]}>
                  <label htmlFor="interest">How will you use Project Moose?</label>
                  <textarea
                    id="interest"
                    value={interest}
                    onChange={(e) => setInterest(e.target.value)}
                    placeholder="Please describe your interest in using Project Moose (Optional)"
                    rows="3"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles["form-group"]}>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                />
              </div>
              
              <div className={styles["form-group"]}>
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="current-password"
                />
              </div>
            </>
          )}
          
          <button 
            type="submit" 
            className={styles["login-btn"]}
            disabled={loading}
          >
            {loading ? 'Processing...' : 
              activeTab === 'requestAccess' ? 'Submit Request' : 
              activeTab === 'login' ? 'Sign In' : 
              'Validate Key'}
          </button>
        </form>
        
        <div className={styles["login-footer"]}>
          <p>This is a research preview. For medical professionals only.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;