import { useState, useCallback, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';

/**
 * Custom hook for handling image uploads, view state management, and analysis
 * @param {Object} options Configuration options
 * @param {Object} options.apiService API service for image analysis
 * @param {string} options.apiEndpoint API endpoint for analysis
 * @returns {Object} Image state and handler functions
 */
const useImageAnalysis = ({ apiService, apiEndpoint }) => {
  // State for image handling
  const [hasImage, setHasImage] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [viewState, setViewState] = useState('animation');
  
  // State for analysis
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [analysisCount, setAnalysisCount] = useState(0);
  
  // Get auth context
  const auth = useContext(AuthContext);
  
  // Log view state changes for debugging
  useEffect(() => {
    console.log('View state changed:', viewState);
  }, [viewState]);

  /**
   * Handles image upload from file input or drag & drop
   * @param {File} file The uploaded image file
   */
  const handleImageUpload = useCallback((file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      // Update state
      setImageSrc(e.target.result);
      setHasImage(true);
      
      // Reset results when a new image is uploaded
      setShowResults(false);
      setAnalysisResults(null);
      
      console.log('Image uploaded');
    };
    reader.readAsDataURL(file);
  }, []);

  /**
   * Handles "VIEW IN CANVAS" button click
   */
  const handleViewInCanvas = useCallback(() => {
    if (hasImage) {
      // Only transition if we're not already in 'image' view
      if (viewState !== 'image') {
        setViewState('transitioning');
        
        setTimeout(() => {
          setViewState('image');
        }, 100);
      }
    }
  }, [hasImage, viewState]);

  /**
   * Placeholder for storing analysis results
   * (Supabase implementation has been removed as part of refactoring)
   * @param {Object} analysisData Analysis data to store
   */
  const storeAnalysisResults = useCallback(async (analysisData) => {
    console.log('Analysis storage functionality temporarily disabled during refactoring', analysisData);
    // This is a placeholder for the future implementation of analysis storage
  }, []);

  /**
   * Handles image analysis with a specific prompt
   * @param {string} prompt The analysis prompt
   * @param {string} promptType The type of prompt (default, full_report, etc.)
   */
  const handleAnalyze = useCallback(async (prompt, promptType = 'default') => {
    if (hasImage) {
      try {
        setIsLoading(true);
        setError(null);
        setShowResults(false); // Reset results display
        
        // Default prompt for medical image analysis - matches backend default
        const defaultPrompt = "Analyze this medical image in detail. Identify findings, potential diagnoses, and provide recommendations.";
        
        // Use the provided prompt if available, otherwise use the default
        const analysisPrompt = prompt && prompt.trim() !== "" ? prompt : defaultPrompt;
        
        // Call the Beast Mode API to analyze the image
        console.log('Analyzing image with prompt:', analysisPrompt);
        console.log('Prompt type:', promptType);
        
        const results = await apiService.analyzeImage(imageSrc, apiEndpoint, analysisPrompt);
        
        // Generate unique identifiers for this analysis
        const timestamp = new Date().toISOString();
        const analysisId = `MOOSE-${Math.floor(Math.random() * 10000).toString().padStart(4, '0')}`;
        const sessionId = uuidv4();
        
        // Increment analysis count
        setAnalysisCount(prevCount => prevCount + 1);
        
        // Create userId based on session ID
        const userId = `user-${sessionId.split('-')[0]}`;
        
        // Add metadata to results
        const enhancedResults = {
          ...results,
          metadata: {
            analysisId,
            timestamp,
            sessionId,
            userId,
            promptType,
            totalSessionAnalyses: analysisCount + 1,
            modelVersion: "Project Moose v1.2.1",
            processingTime: results.processing_time || 0,
            reportId: `MV${Date.now().toString().slice(-8)}${Math.floor(Math.random() * 1000)}`
          }
        };
        
        // This would normally store analysis data in a database
        // Supabase functionality has been removed
        await storeAnalysisResults({
          analysisId,
          promptType,
          prompt: analysisPrompt,
          result: results.raw_response,
          metadata: enhancedResults.metadata
        });
        
        // Make sure the response is properly structured for the DocumentationView component
        if (results.response && !results.raw_response) {
          results.raw_response = results.response;
        }
        
        // Update state with enhanced results
        setAnalysisResults(enhancedResults);
        setShowResults(true);
        
        // Dispatch event for module integration
        if (window.dispatchEvent) {
          window.dispatchEvent(new CustomEvent('moose:imageAnalyzed', {
            detail: { results: enhancedResults, prompt: analysisPrompt }
          }));
        }
        
      } catch (err) {
        console.error('Error analyzing image:', err);
        
        // Show detailed error message to the user
        setError(`Failed to analyze image: ${err.message}`);
        
        // Don't show results panel when there's an error
        setShowResults(false);
        setAnalysisResults(null);
        
        // Dispatch error event
        if (window.dispatchEvent) {
          window.dispatchEvent(new CustomEvent('moose:error', {
            detail: { message: 'Failed to analyze image', error: err }
          }));
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [analysisCount, apiEndpoint, apiService, hasImage, imageSrc, storeAnalysisResults]);

  // Return all state variables and handler functions
  return {
    // State
    hasImage,
    imageSrc,
    viewState,
    isLoading,
    error,
    showResults,
    analysisResults,
    
    // Handlers
    handleImageUpload,
    handleViewInCanvas,
    handleAnalyze,
    
    // Setters - for components that need direct access
    setViewState,
    setShowResults,
    setAnalysisResults
  };
};

export default useImageAnalysis;
