import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import styles from './AdminPanel.module.css';

const AdminPanel = () => {
  const { profile, getAllUsers, getResearchKeys, createResearchKey, batchCreateResearchKeys } = useAuth();
  
  const [activeTab, setActiveTab] = useState('keys');
  const [users, setUsers] = useState([]);
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  // New key form state
  const [newKey, setNewKey] = useState({
    name: '',
    description: '',
    key_value: '',
    prefix: 'MOOSE-KEY',
    max_uses: '',
    duration: '',
    expires_at: ''
  });
  
  // Batch key form state
  const [batchKeys, setBatchKeys] = useState({
    names: '',
    description: '',
    max_uses: '',
    expires_at: '',
    keyType: 'research'
  });
  
  // Success message state
  const [successMessage, setSuccessMessage] = useState('');

  // Check if user is admin
  const isAdmin = profile?.is_admin === true;

  const loadData = useCallback(async () => {
    setLoading(true);
    setError('');
    
    try {
      if (activeTab === 'keys') {
        const { data, error } = await getResearchKeys();
        if (error) throw new Error(error);
        setKeys(data || []);
      } else if (activeTab === 'users') {
        const { data, error } = await getAllUsers();
        if (error) throw new Error(error);
        setUsers(data || []);
      }
    } catch (err) {
      setError(`Error loading data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [activeTab, getResearchKeys, getAllUsers]);

  useEffect(() => {
    if (isAdmin) {
      loadData();
    }
  }, [isAdmin, activeTab, loadData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewKey(prev => ({ ...prev, [name]: value }));
  };
  
  const handleBatchInputChange = (e) => {
    const { name, value } = e.target;
    setBatchKeys(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateKey = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');
    
    try {
      // Format the data
      const keyData = {
        ...newKey,
        max_uses: newKey.max_uses ? parseInt(newKey.max_uses) : null,
        expires_at: newKey.expires_at ? new Date(newKey.expires_at).toISOString() : null,
        duration: newKey.duration ? parseInt(newKey.duration) : null
      };
      
      const result = await createResearchKey(keyData);
      if (result.error) throw new Error(result.error);
      
      // Show success message
      setSuccessMessage(`Research key "${result.name || result.key_value}" created successfully!`);
      
      // Reset form
      setNewKey({
        name: '',
        description: '',
        key_value: '',
        prefix: 'MOOSE-KEY',
        max_uses: '',
        duration: '',
        expires_at: ''
      });
      
      // Reload keys
      loadData();
    } catch (err) {
      setError(`Error creating key: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  const handleBatchCreateKeys = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');
    
    try {
      // Format the options
      const options = {
        description: batchKeys.description,
        max_uses: batchKeys.max_uses ? parseInt(batchKeys.max_uses) : null,
        usage_limit: batchKeys.max_uses ? parseInt(batchKeys.max_uses) : null, // Add this line to ensure compatibility
        expires_at: batchKeys.expires_at ? new Date(batchKeys.expires_at).toISOString() : null,
        keyType: batchKeys.keyType // Add the key type to options
      };
      
      console.log("Creating keys with options:", options);
      
      const { data, error } = await batchCreateResearchKeys(batchKeys.names, options);
      if (error) throw new Error(error);
      
      // Show success message with appropriate key type name
      const keyTypeName = batchKeys.keyType === 'priority' ? 'priority access' : 'research';
      setSuccessMessage(`${data.length} ${keyTypeName} keys created successfully!`);
      
      // Reset form
      setBatchKeys({
        names: '',
        description: '',
        max_uses: '',
        expires_at: '',
        keyType: 'research'
      });
      
      // Reload keys
      loadData();
    } catch (err) {
      setError(`Error creating keys: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (!isAdmin) {
    return (
      <div className={styles.adminPanel}>
        <div className={styles.adminError}>
          <h2>Access Denied</h2>
          <p>You do not have permission to access the admin panel.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.adminPanel}>
      <h1>Admin Panel</h1>
      
      <div className={styles.adminTabs}>
        <button 
          className={`${styles.tabButton} ${activeTab === 'keys' ? styles.active : ''}`}
          onClick={() => setActiveTab('keys')}
        >
          Research Keys
        </button>
        <button 
          className={`${styles.tabButton} ${activeTab === 'users' ? styles.active : ''}`}
          onClick={() => setActiveTab('users')}
        >
          User Accounts
        </button>
      </div>
      
      {error && <div className={styles.adminError}>{error}</div>}
      {successMessage && <div className={styles.adminSuccess}>{successMessage}</div>}
      
      {activeTab === 'keys' && (
        <div className={styles.adminSection}>
          <h2>Research Keys</h2>
          
          <div className={styles.adminCard}>
            <h3>Create New Research Key</h3>
            <form onSubmit={handleCreateKey}>
              <div className={styles.formGroup}>
                <label htmlFor="name">Researcher Name</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={newKey.name}
                  onChange={handleInputChange}
                  placeholder="Dr. Smith's Research Team"
                  required
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="prefix">Key Prefix</label>
                <input
                  id="prefix"
                  name="prefix"
                  type="text"
                  value={newKey.prefix}
                  onChange={handleInputChange}
                  placeholder="MOOSE-KEY"
                />
                <p className={styles.formHelp}>
                  Will be prepended to the generated key, e.g. "MOOSE-KEY-x7dQ2p"
                </p>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="description">Description (Optional)</label>
                <textarea
                  id="description"
                  name="description"
                  value={newKey.description}
                  onChange={handleInputChange}
                  placeholder="Access for cardiology research team"
                />
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="key_value">Key Value (Optional - will be generated if empty)</label>
                <input
                  id="key_value"
                  name="key_value"
                  type="text"
                  value={newKey.key_value}
                  onChange={handleInputChange}
                  placeholder="MOOSE-RESEARCH-123"
                />
              </div>
              
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="max_uses">Max Uses (Optional)</label>
                  <input
                    id="max_uses"
                    name="max_uses"
                    type="number"
                    min="1"
                    value={newKey.max_uses}
                    onChange={handleInputChange}
                    placeholder="100"
                  />
                  <p className={styles.formHelp}>
                    Number of analyses that can be performed with this key
                  </p>
                </div>
                
                <div className={styles.formGroup}>
                  <label htmlFor="duration">Duration in Days (Optional)</label>
                  <input
                    id="duration"
                    name="duration"
                    type="number"
                    min="1"
                    value={newKey.duration}
                    onChange={handleInputChange}
                    placeholder="30"
                  />
                </div>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="expires_at">Expiration Date (Optional)</label>
                <input
                  id="expires_at"
                  name="expires_at"
                  type="datetime-local"
                  value={newKey.expires_at}
                  onChange={handleInputChange}
                />
                <p className={styles.formHelp}>
                  Overrides Duration if both are set
                </p>
              </div>
              
              <button 
                type="submit" 
                className={styles.adminButton}
                disabled={loading}
              >
                {loading ? 'Creating...' : 'Create Research Key'}
              </button>
            </form>
          </div>
          
          <div className={styles.adminCard}>
            <h3>Batch Create Research Keys</h3>
            <form onSubmit={handleBatchCreateKeys}>
              <div className={styles.formGroup}>
                <label htmlFor="batch_names">List of Researchers (One per line)</label>
                <textarea
                  id="batch_names"
                  name="names"
                  value={batchKeys.names}
                  onChange={handleBatchInputChange}
                  placeholder="Dr. John Sethi&#10;Dr. Mary Akl&#10;Dr. Tom Pasadyn"
                  rows="7"
                  required
                />
                <div className={styles.formHelp}>
                  Enter each researcher's name on a new line. Keys will be generated based on the key type selected below.
                </div>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="batch_key_type">Key Type</label>
                <select
                  id="batch_key_type"
                  name="keyType"
                  value={batchKeys.keyType}
                  onChange={handleBatchInputChange}
                >
                  <option value="research">Research Key (RSRCHKEY-)</option>
                  <option value="priority">Priority Access Key (PRIORITYACCESSKEY-)</option>
                </select>
                <div className={styles.formHelp}>
                  Select the type of key to generate. This determines the prefix used in the key format.
                </div>
              </div>
              
              <div className={styles.formGroup}>
                <label htmlFor="batch_description">Description (Optional - applied to all keys)</label>
                <textarea
                  id="batch_description"
                  name="description"
                  value={batchKeys.description}
                  onChange={handleBatchInputChange}
                  placeholder="Access for research study participants"
                />
              </div>
              
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="batch_max_uses">Analyses per Key (Optional)</label>
                  <input
                    id="batch_max_uses"
                    name="max_uses"
                    type="number"
                    min="1"
                    value={batchKeys.max_uses}
                    onChange={handleBatchInputChange}
                    placeholder="100"
                  />
                </div>
                
                <div className={styles.formGroup}>
                  <label htmlFor="batch_expires_at">Expiration Date (Optional)</label>
                  <input
                    id="batch_expires_at"
                    name="expires_at"
                    type="datetime-local"
                    value={batchKeys.expires_at}
                    onChange={handleBatchInputChange}
                  />
                </div>
              </div>
              
              <button 
                type="submit" 
                className={styles.adminButton}
                disabled={loading}
              >
                {loading ? 'Creating Keys...' : 'Create Multiple Keys'}
              </button>
            </form>
          </div>
          
          <div className={styles.adminCard}>
            <h3>Existing Research Keys</h3>
            {loading ? (
              <p>Loading keys...</p>
            ) : keys.length === 0 ? (
              <p>No research keys found.</p>
            ) : (
              <div className={styles.adminTableContainer}>
                <table className={styles.adminTable}>
                  <thead>
                    <tr>
                      <th>Researcher Name</th>
                      <th>Key Value</th>
                      <th>Uses</th>
                      <th>Expires</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {keys.map(key => (
                      <tr key={key.id}>
                        <td>
                          <div>{key.name || key.researcher_name || 'Unnamed Key'}</div>
                          {key.description && <div className={styles.tableDescription}>{key.description}</div>}
                        </td>
                        <td><code>{key.key_value}</code></td>
                        <td>
                          {(key.max_uses - (key.uses_remaining || 0)) || 0} / {key.max_uses || '∞'}
                        </td>
                        <td>
                          {key.expires_at ? 
                            new Date(typeof key.expires_at === 'object' && key.expires_at.toDate ? 
                              key.expires_at.toDate() : key.expires_at).toLocaleDateString() 
                            : 'Never'}
                        </td>
                        <td>
                          <span className={`${styles.statusBadge} ${key.is_active ? styles.active : styles.inactive}`}>
                            {key.is_active ? 'Active' : 'Inactive'}
                          </span>
                        </td>
                        <td>
                          <button 
                            className={styles.tableButton}
                            onClick={() => {
                              // Copy key to clipboard
                              navigator.clipboard.writeText(key.key_value);
                              setSuccessMessage(`Key "${key.key_value}" copied to clipboard!`);
                              
                              // Hide success message after 3 seconds
                              setTimeout(() => {
                                setSuccessMessage('');
                              }, 3000);
                            }}
                          >
                            Copy
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
      
      {activeTab === 'users' && (
        <div className={styles.adminSection}>
          <h2>User Accounts</h2>
          
          <div className={styles.adminCard}>
            <h3>Registered Users</h3>
            {loading ? (
              <p>Loading users...</p>
            ) : users.length === 0 ? (
              <p>No users found.</p>
            ) : (
              <div className={styles.adminTableContainer}>
                <table className={styles.adminTable}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Role</th>
                      <th>Hospital</th>
                      <th>Joined</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(user => (
                      <tr key={user.id}>
                        <td>
                          {user.full_name || `${user.first_name || ''} ${user.last_name || ''}`}
                        </td>
                        <td>{user.username || 'N/A'}</td>
                        <td>{user.role || 'N/A'}</td>
                        <td>{user.hospital || 'N/A'}</td>
                        <td>
                          {user.created_at ? new Date(user.created_at).toLocaleDateString() : 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
