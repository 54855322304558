import React, { useState, useEffect } from 'react';
import styles from './ImageView.module.css';

export function ImageView({ viewState, imageSrc }) {
  const [imageVisible, setImageVisible] = useState(false);
  
  // Handle view state change to image
  useEffect(() => {
    if (viewState === 'ImageView' || viewState === 'transitioning') {
      // Show image immediately
      setImageVisible(true);
    }
  }, [viewState]);
  
  return (
    <div className={styles.imageView}>
      <div className={`${styles.canvasBoundary} ${styles[viewState] || ''}`}>
        {/* Image with visibility control */}
        {imageSrc && (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img 
            src={imageSrc} 
            alt="Medical Image" 
            className={`${styles.mainImage} ${imageVisible ? styles.visible : ''}`}
          />
        )}
      </div>
    </div>
  );
}

// Set displayName for component identification
ImageView.displayName = 'ImageView';

export default ImageView;
