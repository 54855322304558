import React, { useState, useEffect, createContext, useMemo } from 'react';
import styles from './CanvasViewManager.module.css';
import TabNavigation from '../TabNavigation';

// Create a context for view state management
export const ViewStateContext = createContext();

export function CanvasViewManager({
  children,
  imageSrc,
  analysisResults,
  showResults,
  isLoading
}) {
  // Extract view information from children using useMemo to avoid recreating on every render
  const views = useMemo(() => {
    return React.Children.map(children, child => ({
      id: child.type.displayName || child.type.name,
      label: child.props.label || child.type.displayName || child.type.name,
      icon: child.props.icon
    })) || [];
  }, [children]);

  // Set first view as default active view if available, otherwise default to 'ImageView'
  const defaultView = views.length > 0 ? views[0].id : 'ImageView';
  const [activeView, setActiveView] = useState(defaultView);
  
  // Auto-switch to documentation when results are ready
  useEffect(() => {
    if (showResults && analysisResults) {
      // Find the documentation view by name
      const docView = views.find(v => v.id === 'DocumentationView');
      if (docView) {
        setActiveView(docView.id);
      }
    }
  }, [showResults, analysisResults, views]);

  // Create context value
  const contextValue = {
    activeView,
    setActiveView,
    views
  };

  return (
    <ViewStateContext.Provider value={contextValue}>
      <div className={styles.canvasViewManager}>
        <TabNavigation 
          activeTab={activeView}
          setActiveTab={setActiveView}
          tabs={views}
        />
        
        <div className={styles.viewContainer}>
          {React.Children.map(children, child => {
            const viewId = child.type.displayName || child.type.name;
            
            // Only render the active view
            if (viewId === activeView) {
              // Pass needed props to the view
              return React.cloneElement(child, {
                imageSrc,
                analysisResults,
                showResults,
                isLoading,
                viewState: activeView,
              });
            }
            return null;
          })}
        </div>
      </div>
    </ViewStateContext.Provider>
  );
}

export default CanvasViewManager;
