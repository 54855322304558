import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

/**
 * Custom hook for managing report history
 * @returns {Object} Report history state and handler functions
 */
const useReportHistory = () => {
  // State for report history
  const [reportHistory, setReportHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);

  // Get auth context
  const auth = useContext(AuthContext);

  /**
   * Fetch user's report history from Firebase
   */
  const fetchReportHistory = useCallback(async () => {
    try {
      setLoadingHistory(true);
      
      // Use auth context to get user reports
      if (auth?.getUserReports) {
        const { reports, error } = await auth.getUserReports();
        
        if (error) {
          console.error('Error fetching reports:', error);
          return;
        }
        
        if (reports) {
          // Sort by date created (newest first)
          const sortedReports = [...reports].sort((a, b) => {
            const dateA = a.createdAt?.toDate ? a.createdAt.toDate() : new Date(a.createdAt || 0);
            const dateB = b.createdAt?.toDate ? b.createdAt.toDate() : new Date(b.createdAt || 0);
            return dateB - dateA;
          });
          
          setReportHistory(sortedReports);
        }
      }
    } catch (err) {
      console.error('Error in fetchReportHistory:', err);
    } finally {
      setLoadingHistory(false);
    }
  }, [auth]);

  // Load report history when auth context changes
  useEffect(() => {
    if (auth?.user || auth?.researchKeyAccess) {
      fetchReportHistory();
    }
  }, [auth?.user, auth?.researchKeyAccess, fetchReportHistory]);

  return {
    reportHistory,
    loadingHistory,
    fetchReportHistory
  };
};

export default useReportHistory;
