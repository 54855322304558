import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import styles from './ProfileSetup.module.css';

export function ProfileSetup({ keyData, onComplete, isResearchKey = false }) {
  const { updateProfile, isAuthenticated, loading: authLoading, createUserFromResearchKey, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    prefix: 'MD', // Default selection
    role: '',
    hospital: '',
    university: '',
    specialty: '',
    year: 'Attending' // Default for research key users
  });
  
  const [step, setStep] = useState(1);
  const totalSteps = 3;
  
  // If keyData has name info, populate the form (for research key flow)
  useEffect(() => {
    if (isResearchKey && keyData && keyData.name) {
      // Try to parse name if available
      const nameParts = keyData.name.split(' ');
      if (nameParts.length > 1) {
        setFormData(prev => ({
          ...prev,
          firstName: nameParts[0],
          lastName: nameParts.slice(1).join(' ')
        }));
      }
    }
  }, [keyData, isResearchKey]);
  
  // Redirect if user is not authenticated (only for regular user flow)
  useEffect(() => {
    if (!isResearchKey && !authLoading && !isAuthenticated && !user) {
      // Redirect to login if there's no authenticated user
      navigate('/login');
    }
  }, [authLoading, isAuthenticated, user, navigate, isResearchKey]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    if (error) setError('');
  };
  
  const nextStep = () => {
    if (validateStep(step) && step < totalSteps) {
      setStep(step + 1);
    }
  };
  
  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  
  const validateStep = (currentStep) => {
    switch (currentStep) {
      case 1:
        if (!formData.firstName || !formData.lastName) {
          setError('Please enter both first and last name');
          return false;
        }
        return true;
        
      case 2:
        if (formData.prefix === 'Other' && !formData.customPrefix) {
          setError('Please specify your title');
          return false;
        }
        if (!formData.specialty) {
          setError('Please enter your specialty or program');
          return false;
        }
        return true;
        
      case 3:
        if (!formData.hospital && !formData.university) {
          setError('Please enter your hospital or university');
          return false;
        }
        return true;
        
      default:
        return true;
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep(step)) return;
    
    setLoading(true);
    setError('');
    
    try {
      if (isResearchKey) {
        // For research key users, create a new user profile
        const { success, error: apiError, profile } = await createUserFromResearchKey(
          keyData.id, 
          formData
        );
        
        if (apiError) {
          throw new Error(apiError.message || 'Failed to create profile');
        }
        
        if (success && profile) {
          // Call onComplete callback
          if (onComplete) onComplete(profile);
        }
      } else {
        // For regular users, update their profile
        const profileData = {
          displayName: `${formData.firstName} ${formData.lastName}`,
          firstName: formData.firstName,
          lastName: formData.lastName,
          prefix: formData.prefix === 'Other' ? formData.customPrefix : formData.prefix,
          specialty: formData.specialty,
          hospital: formData.hospital,
          university: formData.university,
          year: formData.year,
          onboardingCompleted: true
        };
        
        const { error: apiError } = await updateProfile(profileData);
        
        if (apiError) throw new Error(apiError.message);
        
        // Call onComplete callback
        if (onComplete) onComplete();
      }
    } catch (err) {
      setError(err.message || 'An error occurred while updating your profile');
    } finally {
      setLoading(false);
    }
  };
  
  // Render different form steps
  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <div className={styles.formStep}>
            <h3>Personal Information</h3>
            <div className={styles.formGroup}>
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="prefix">Title</label>
              <select
                id="prefix"
                name="prefix"
                value={formData.prefix}
                onChange={handleChange}
                required
              >
                <option value="MD">MD</option>
                <option value="Nurse">Nurse</option>
                <option value="Medical Student">Medical Student</option>
                <option value="PhD">PhD</option>
                <option value="Researcher">Researcher</option>
                <option value="Other">Other</option>
              </select>
            </div>
            
            {formData.prefix === 'Other' && (
              <div className={styles.formGroup}>
                <label htmlFor="customPrefix">Specify Title</label>
                <input
                  id="customPrefix"
                  name="customPrefix"
                  type="text"
                  value={formData.customPrefix || ''}
                  onChange={handleChange}
                  placeholder="Your title"
                  required
                />
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className={styles.formStep}>
            <h3>Professional Information</h3>
            <div className={styles.formGroup}>
              <label htmlFor="specialty">Specialty/Program</label>
              <input
                id="specialty"
                name="specialty"
                type="text"
                value={formData.specialty}
                onChange={handleChange}
                placeholder="e.g., Internal Medicine, Cardiology, etc."
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="year">Current Position</label>
              <select
                id="year"
                name="year"
                value={formData.year}
                onChange={handleChange}
                required
              >
                <option value="Attending">Attending</option>
                <option value="Fellow">Fellow</option>
                <option value="Resident">Resident</option>
                <option value="Student">Student</option>
                <option value="Nurse Practitioner">Nurse Practitioner</option>
                <option value="Physician Assistant">Physician Assistant</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.formStep}>
            <h3>Institution Information</h3>
            <div className={styles.formGroup}>
              <label htmlFor="hospital">Hospital/Institution</label>
              <input
                id="hospital"
                name="hospital"
                type="text"
                value={formData.hospital}
                onChange={handleChange}
                placeholder="Hospital name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="university">University/Medical School</label>
              <input
                id="university"
                name="university"
                type="text"
                value={formData.university}
                onChange={handleChange}
                placeholder="University name"
              />
            </div>
            
            <div className={styles.formSummary}>
              <h4>Profile Summary</h4>
              <p><strong>Name:</strong> {formData.prefix === 'Other' ? formData.customPrefix : formData.prefix} {formData.firstName} {formData.lastName}</p>
              <p><strong>Specialty:</strong> {formData.specialty || 'Not specified'}</p>
              <p><strong>Position:</strong> {formData.year}</p>
              <p><strong>Institution:</strong> {formData.hospital || formData.university || 'Not specified'}</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  return (
    <div className={styles.profileSetupContainer}>
      <div className={styles.profileSetupCard}>
        <h2>{isResearchKey ? 'Welcome to Project Moose' : 'Complete Your Profile'}</h2>
        <p>{isResearchKey 
          ? 'Thanks for using your research key! Please set up your profile to continue.' 
          : 'Please provide some additional information to personalize your experience.'}</p>
        
        {error && <div className={styles.errorMessage}>{error}</div>}
        
        <div className={styles.progressBar}>
          <div 
            className={styles.progressBarFill} 
            style={{ width: `${(step / totalSteps) * 100}%` }}
          ></div>
        </div>
        <div className={styles.stepIndicator}>
          Step {step} of {totalSteps}
        </div>
        
        <form onSubmit={handleSubmit}>
          {renderStep()}
          
          <div className={styles.formNavigation}>
            {step > 1 && (
              <button 
                type="button" 
                className={styles.backBtn} 
                onClick={prevStep}
                disabled={loading}
              >
                Back
              </button>
            )}
            
            {step < totalSteps ? (
              <button 
                type="button" 
                className={styles.nextBtn} 
                onClick={nextStep}
              >
                Next
              </button>
            ) : (
              <button 
                type="submit" 
                className={styles.submitBtn}
                disabled={loading}
              >
                {loading ? 'Saving...' : isResearchKey ? 'Create Account' : 'Complete Setup'}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfileSetup;