import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import { useAuth } from '../../../contexts/AuthContext';

/**
 * @typedef {Object} User
 * @property {string} name - User's display name
 * @property {string} email - User's email address
 * @property {string} [avatar] - Optional URL to user's avatar image
 */

/**
 * @typedef {Object} HeaderProps
 * @property {User} user - Current user object
 * @property {boolean} isAdmin - Whether the current user has admin privileges
 * @property {() => void} onLogout - Logout callback function
 */

/**
 * Header component with navigation and user profile
 * @param {HeaderProps} props
 */
export function Header({ user: propUser, isAdmin: propIsAdmin, onLogout, onShowLoginModal }) {
  const { profile, user: authUser, signOut, getResearchUser, isAuthenticated } = useAuth();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(propIsAdmin || false);
  
  // Effect to fetch research user if available
  useEffect(() => {
    const fetchResearchUser = async () => {
      const { profile: researchProfile } = await getResearchUser();
      if (researchProfile) {
        setCurrentUser({
          name: researchProfile.displayName,
          email: researchProfile.email || 'Research User'
        });
      }
    };
    
    if (!currentUser?.name && !propUser?.name) {
      fetchResearchUser();
    }
  }, [getResearchUser, currentUser, propUser]);
  
  // Effect to update user from auth context if available
  useEffect(() => {
    if (profile) {
      // Only set currentUser if we have a real authenticated user or research user
      if (profile.isResearchUser || authUser) {
        setCurrentUser({
          name: profile.displayName || `${profile.firstName || ''} ${profile.lastName || ''}`.trim(),
          email: profile.email || authUser?.email,
          avatar: profile.photoURL
        });
        setIsAdmin(profile.role === 'admin');
      } else {
        setCurrentUser(null);
      }
    } else if (propUser && propUser.email && propUser.name !== 'Guest') {
      // Only consider propUser if it has an email (real user)
      // Don't treat "Guest" as a real user
      setCurrentUser(propUser);
      setIsAdmin(propIsAdmin);
    } else {
      // Ensure we reset to null if no authenticated user
      setCurrentUser(null);
    }
  }, [profile, authUser, propUser, propIsAdmin]);
  
  // Handle logout
  const handleLogout = async () => {
    try {
      const { success, error } = await signOut();
      
      if (error) {
        console.error("Logout error:", error);
        return;
      }
      
      // Clear local state
      setCurrentUser(null);
      setIsAdmin(false);
      
      if (onLogout) onLogout();
      
      // Optional: you can still reload, but it's better to manage state
      // This prevents infinite reload issues
      setTimeout(() => {
        window.location.href = '/'; // Navigate to home instead of reload
      }, 100);
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  const toggleProfile = () => setIsProfileOpen(prev => !prev);

  const closeProfile = () => setIsProfileOpen(false);

  // Get user initials for avatar
  const userInitials = currentUser?.name
    ?.split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase() || '?';

  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <nav className={styles.navigation}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <Link 
                to="/" 
                className={location.pathname === '/' ? styles.navLinkActive : styles.navLink}
              >
                Dashboard
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link 
                to="/analysis" 
                className={location.pathname === '/analysis' ? styles.navLinkActive : styles.navLink}
              >
                Analysis
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link 
                to="/history" 
                className={location.pathname === '/history' ? styles.navLinkActive : styles.navLink}
              >
                History
              </Link>
            </li>
          </ul>

          {isAdmin && (
            <Link to="/admin" className={styles.adminLink}>
              Admin Panel
            </Link>
          )}

          {currentUser && isAuthenticated ? (
            // Show user profile for authenticated users
            <div className={styles.userProfile}>
              <span className={styles.userName}>Welcome, {currentUser.name}</span>
              
              <div className={styles.profileMenu}>
                <button 
                  className={styles.profileButton}
                  onClick={toggleProfile}
                  aria-expanded={isProfileOpen}
                  aria-label="Open profile menu"
                >
                  {currentUser.avatar ? (
                    <img src={currentUser.avatar} alt="" />
                  ) : (
                    <span>{userInitials}</span>
                  )}
                </button>

                {isProfileOpen && (
                  <>
                    <div 
                      className={styles.profileDropdown}
                      role="menu"
                      aria-label="Profile menu"
                    >
                      <div className={styles.dropdownItem}>
                        <strong>{currentUser.name}</strong>
                        <div>{currentUser.email}</div>
                      </div>
                      
                      <Link 
                        to="/settings" 
                        className={styles.dropdownItem}
                        onClick={closeProfile}
                        role="menuitem"
                      >
                        Settings
                      </Link>
                      
                      <button 
                        className={styles.logoutButton}
                        onClick={() => {
                          closeProfile();
                          handleLogout();
                        }}
                        role="menuitem"
                      >
                        Log Out
                      </button>
                    </div>
                    
                    {/* Click outside handler */}
                    <div 
                      onClick={closeProfile}
                      style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 999
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            // Show login/signup buttons for guest users
            <div className={styles.authButtons}>
              <button 
                className={styles.loginButton}
                onClick={onShowLoginModal}
              >
                Sign In
              </button>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}

export default Header;
