import React from 'react';
import { Header } from '../Header';
import { LeftPanel } from '../LeftPanel';
import { RightPanel } from '../RightPanel';
import { Footer } from '../Footer';
import { CanvasViewManager } from '../../canvas/CanvasViewManager';
import ImageView from '../../canvas/ImageView';
import DocumentationView from '../../canvas/DocumentationView';
import AdvancedDiagnostics from '../../canvas/AdvancedDiagnostics';
import styles from './MainLayout.module.css';

export function MainLayout({
  leftPanelProps,
  rightPanelProps,
  imageSrc,
  analysisResults,
  showResults,
  isLoading,
  user,
  isAdmin,
  onLogout,
  onShowLoginModal
}) {
  return (
    <div className={styles.mainLayout}>
      <header className={styles.header}>
        <Header 
          user={user}
          isAdmin={isAdmin}
          onLogout={onLogout}
          onShowLoginModal={onShowLoginModal}
        />
      </header>

      <aside className={styles.leftPanel}>
        <LeftPanel {...leftPanelProps} />
      </aside>

      <main className={styles.content}>
        <CanvasViewManager
          imageSrc={imageSrc}
          analysisResults={analysisResults}
          showResults={showResults}
          isLoading={isLoading}
        >
          <ImageView label="Image View" />
          <DocumentationView label="Documentation" />
          <AdvancedDiagnostics label="Advanced Diagnostics" />
        </CanvasViewManager>
      </main>

      <aside className={styles.rightPanel}>
        <RightPanel {...rightPanelProps} />
      </aside>

      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  );
}

export default MainLayout;
