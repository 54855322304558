import React, { useState, useEffect } from 'react';
import styles from './LoadingOverlay.module.css';

const LoadingOverlay = ({ isLoading, loadingText = 'moose is working on it...' }) => {
  const [messageText, setMessageText] = useState(loadingText);
  const [showText, setShowText] = useState(true);
  
  // Update message text if prop changes
  useEffect(() => {
    setMessageText(loadingText);
  }, [loadingText]);
  
  // Animation effect for the loading text
  useEffect(() => {
    if (!isLoading) return;
    
    // Toggle the text visibility every 2 seconds
    const textInterval = setInterval(() => {
      setShowText(prev => !prev);
    }, 2000);
    
    return () => clearInterval(textInterval);
  }, [isLoading]);
  
  if (!isLoading) return null;
  
  return (
    <div className={styles['loading-overlay']}>
      <div className={styles['loading-container']}>
        <div className={styles['research-preview-banner']}>
          THIS IS A RESEARCH PREVIEW
        </div>
        <div className={styles['loading-animation']}>
          {/* Animated SVG logo with line-drawing effect for "2.png" */}
          <svg
            className={`${styles['logo-svg']} ${styles['svg-glow']}`}
            viewBox="0 0 240 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Main logo outline */}
            <path
              className={styles['svg-path']}
              d="M30,20 C45,20 60,30 70,50 C80,70 85,80 100,80 C115,80 125,75 140,60 C155,45 165,40 180,40 C195,40 210,50 210,65 C210,80 195,90 180,90 C165,90 155,80 155,65 C155,50 165,40 180,40"
            />
            
            {/* Secondary decorative elements */}
            <path
              className={styles['svg-path']}
              d="M50,30 C60,45 70,60 100,60 C130,60 140,45 150,30"
            />
            
            {/* Additional details */}
            <path 
              className={styles['svg-path']}
              d="M70,70 C90,65 110,65 130,70" 
            />
            
            {/* Finishing artistic flourish */}
            <path
              className={styles['svg-path']}
              d="M40,50 C70,40 100,40 130,50 C160,60 190,60 210,50"
            />
          </svg>
        </div>
        <p className={`${styles['loading-message']} ${showText ? styles.visible : styles.hidden}`}>
          {messageText}
        </p>
      </div>
    </div>
  );
};

export default LoadingOverlay;
