import { useState, useEffect, useCallback } from 'react';

/**
 * Custom hook for app settings, device detection, and server health
 * @param {Object} options Configuration options
 * @param {Function} options.checkHealthFunction Function to check server health
 * @returns {Object} App settings state and functions
 */
const useAppSettings = ({ checkHealthFunction }) => {
  // State for device detection
  const [isMobile, setIsMobile] = useState(false);
  
  // State for server health
  const [serverHealth, setServerHealth] = useState(null);
  
  /**
   * Detects mobile devices and small screens
   */
  const detectMobile = useCallback(() => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isSmallScreen = window.innerWidth < 768;
    setIsMobile(isMobileDevice || isSmallScreen);
  }, []);

  /**
   * Checks server health
   */
  const checkServerHealth = useCallback(async () => {
    try {
      if (typeof checkHealthFunction !== 'function') {
        console.warn('Health check function not provided');
        return;
      }
      
      const health = await checkHealthFunction();
      setServerHealth(health);
      console.log('Beast Mode server health:', health);
    } catch (err) {
      console.error('Error checking server health:', err);
      setServerHealth({ status: 'error', message: err.message });
    }
  }, [checkHealthFunction]);

  /**
   * Gets metadata for the application
   */
  const getMetadata = useCallback(() => {
    // Construct metadata object to provide consistent values
    return {
      title: "Moose Vision",
      version: "Project Moose v1.2.1",
      environment: process.env.NODE_ENV
    };
  }, []);

  // Setup effects on mount
  useEffect(() => {
    // Document title
    document.title = "Moose Vision";
    
    // Log environment variables for debugging
    console.log('Environment Variables:');
    console.log('NODE_ENV:', process.env.NODE_ENV);
    
    // Mobile detection
    detectMobile();
    window.addEventListener('resize', detectMobile);
    
    // Check server health
    checkServerHealth();
    
    // App initialization logs
    console.log('App component mounted');
    
    // Cleanup function
    return () => {
      console.log('App component unmounted');
      window.removeEventListener('resize', detectMobile);
    };
  }, [checkServerHealth, detectMobile]);

  return {
    isMobile,
    serverHealth,
    getMetadata,
    checkServerHealth
  };
};

export default useAppSettings;
