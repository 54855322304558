import React, { useState, useContext } from 'react';
import styles from './AdvancedDiagnostics.module.css';
import { ViewStateContext } from '../CanvasViewManager';

export function AdvancedDiagnostics({ viewState }) {
  // Use context to get the view state context
  const { setActiveView } = useContext(ViewStateContext);
  
  // State for form fields
  const [formData, setFormData] = useState({
    // Case Study Section
    patientSummary: '',
    medicalHistory: '',
    socialHistory: '',
    relevantMedications: '',
    relevantSymptoms: '',
    
    // Objective Section
    objectiveData: '',
    
    // Gestalt Analysis Section
    clinicalQuestion: '',
    presentationArchetype: '',
    clinicalIntent: [],
    imagingTimeline: '',
    symptomTimeline: '',
    temporalAnalysis: '',
    majorContradictions: '',
    redFlags: [],
    diagnosticState: '',
    primaryHypothesis: '',
    diagnosticConfidence: 50,
    supportingFactors: '',
    contradictingFactors: '',
    critiqueLevel: 'balanced',
    vulnerabilityTesting: '',
    
    // Specialty Modes
    emergencyMode: false,
    oncologyMode: false
  });

  // Update form data when a field changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkbox vs text inputs differently
    if (type === 'checkbox') {
      if (name.includes('.')) {
        // Handle nested objects (e.g., focusAreas.anatomy)
        const [parent, child] = name.split('.');
        setFormData(prev => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: checked
          }
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          [name]: checked
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };
  
  // Handle checkbox arrays (like clinicalIntent and redFlags)
  const handleCheckboxArrayChange = (e) => {
    const { name, value, checked } = e.target;
    
    setFormData(prev => {
      if (checked) {
        // Add value to array if checked
        return {
          ...prev,
          [name]: [...prev[name], value]
        };
      } else {
        // Remove value from array if unchecked
        return {
          ...prev,
          [name]: prev[name].filter(item => item !== value)
        };
      }
    });
  };

  // Generate structured prompt from form data
  const generateStructuredPrompt = () => {
    // Start with template header
    let prompt = `You need to generate a report based on this image. You need to act as a senior physician with clinical gestault and radiologic expertise. The 
    Analyze the provided medical image THROUGH THE LENS OF THE CLINICAL CONTENT SHOWN BELOW:
    
    [Insert clinical data here]
    `;

    
    // Clinical intent section (always first)
    if (formData.clinicalQuestion) {
      prompt += `Clinical Question: ${formData.clinicalQuestion}\n\n`;
    }
    
    if (formData.clinicalIntent && formData.clinicalIntent.length > 0) {
      prompt += "Intent for AI Analysis:\n";
      formData.clinicalIntent.forEach(intent => {
        prompt += `- ${intent}\n`;
      });
      prompt += "\n";
    }
    
    // Case summary section
    if (formData.patientSummary) {
      prompt += `Case Summary: ${formData.patientSummary}\n\n`;
    }
    
    // History section
    let hasHistory = formData.medicalHistory || formData.socialHistory || 
                    formData.relevantMedications || formData.relevantSymptoms;
    
    if (hasHistory) {
      prompt += "HISTORY:\n";
      
      if (formData.medicalHistory) {
        prompt += `Medical History: ${formData.medicalHistory}\n`;
      }
      
      if (formData.socialHistory) {
        prompt += `Social History: ${formData.socialHistory}\n`;
      }
      
      if (formData.relevantMedications) {
        prompt += `Relevant Medications: ${formData.relevantMedications}\n`;
      }
      
      if (formData.relevantSymptoms) {
        prompt += `Relevant Symptoms: ${formData.relevantSymptoms}\n`;
      }
      
      prompt += "\n";
    }
    
    // Objective data section
    if (formData.objectiveData) {
      prompt += "OBJECTIVE DATA:\n";
      prompt += `${formData.objectiveData}\n\n`;
    }
    
    // Clinical context section
    prompt += "CLINICAL CONTEXT:\n";
    
    if (formData.presentationArchetype) {
      prompt += `Presentation Archetype: ${formData.presentationArchetype}\n`;
    }
    
    if (formData.imagingTimeline) {
      prompt += `Imaging Timeline: ${formData.imagingTimeline}\n`;
    }
    
    if (formData.symptomTimeline) {
      prompt += `Symptom Timeline: ${formData.symptomTimeline}\n`;
    }
    
    if (formData.temporalAnalysis) {
      prompt += `Temporal Analysis: ${formData.temporalAnalysis}\n`;
    }
    
    if (formData.majorContradictions) {
      prompt += `Major Contradictions: ${formData.majorContradictions}\n`;
    }
    
    if (formData.redFlags && formData.redFlags.length > 0) {
      prompt += `Red Flags: ${formData.redFlags.join(', ')}\n`;
    }
    
    // Diagnostic hypothesis section
    if (formData.diagnosticState) {
      prompt += `Diagnostic State: ${formData.diagnosticState}\n`;
      
      if (formData.primaryHypothesis) {
        prompt += `Primary Hypothesis: ${formData.primaryHypothesis} (${formData.diagnosticConfidence}% confidence)\n`;
        
        if (formData.supportingFactors) {
          prompt += `Supporting Factors: ${formData.supportingFactors}\n`;
        }
        
        if (formData.contradictingFactors) {
          prompt += `Contradicting Factors: ${formData.contradictingFactors}\n`;
        }
      }
    }
    
    prompt += "\n";
    
    // Analysis parameters section
    prompt += "ANALYSIS PARAMETERS:\n";
    prompt += `Critique Level: ${formData.critiqueLevel}\n`;
    
    if (formData.vulnerabilityTesting) {
      prompt += `Specific Question: ${formData.vulnerabilityTesting}\n`;
    }
    
    if (formData.emergencyMode) {
      prompt += "EMERGENCY MODE: Prioritize urgent findings and management.\n";
    }
    
    if (formData.oncologyMode) {
      prompt += "ONCOLOGY MODE: Focus on staging, prognostic markers, and oncology-specific insights.\n";
    }
    
    // Report instruction footer
    prompt += "\n\nPlease provide a complete detailed and medical report of the medical image, utilizing the helpful clinical data to steer/guide your diagnosis, explain clincial reasoining, discuss likelihoods/differntials, and answer the users questions. organize it into\n\n";
    
    prompt += "1. OVERVIEW:\n";
    prompt += "- Describe the type of imaging (X-ray, CT, MRI, ultrasound, photograph, etc.)\n";
    prompt += "- Comment on image quality and positioning\n";
    prompt += "- Briefly mention the most significant finding(s)\n\n";
    
    prompt += "2. FINDINGS:\n";
    prompt += "- List all abnormalities in order of clinical significance\n";
    prompt += "- For each finding, describe location, size, characteristics and relation to surrounding structures\n\n";
    
    prompt += "3. IMPRESSION:\n";
    prompt += "Synthesize your overall assessment of the medical image in relation to relevant clinical data. Focus on:\n";
    prompt += "- Key findings across anatomical regions with their clinical significance\n";
    prompt += "- Most probable diagnosis followed by reasonable alternatives\n";
    prompt += "- Clinical reasoning that integrates image features with patient context\n";
    prompt += "- Degree of correlation between imaging findings and presented clinical scenario\n";
    prompt += "- Areas of diagnostic uncertainty, when present\n";
    prompt += "- Communicate as one physician would to another\n\n";
    
    prompt += "4. RECOMMENDATIONS:\n";
    prompt += "- Suggest appropriate follow-up imaging if needed. Recommend additional tests or consultations\n";
    prompt += "- If relevant, can add a prognosis\n";
    prompt += "- Conclusion with final thoughts";
    
    return prompt;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Generate the structured prompt
    const structuredPrompt = generateStructuredPrompt();
    
    // Find the LeftPanel component and call its handleAdvancedInput method
    const leftPanelElement = document.querySelector('.leftPanel');
    if (leftPanelElement) {
      // Create a custom event with the data
      const event = new CustomEvent('advancedDiagnosticsSubmit', {
        detail: {
          formData,
          prompt: structuredPrompt,
          promptType: 'advanced_diagnostics'
        },
        bubbles: true
      });
      
      // Dispatch the event on the left panel element
      leftPanelElement.dispatchEvent(event);
      
      // Switch back to the image view
      setActiveView('ImageView');
    } else {
      console.error('Could not find left panel element');
    }
  };

  return (
    <div className={styles.advancedDiagnostics}>
      <div className={styles.header}>
        <h2>🦌 Moose Advanced Diagnostics</h2>
        <p>Customize AI analysis parameters for more targeted results</p>
      </div>
      
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Case Study Container */}
        <div className={styles.container}>
          <h3>Case Study</h3>
          
          <div className={styles.formField}>
            <label htmlFor="patientSummary">📌 One-Liner Case Summary</label>
            <input
              type="text"
              id="patientSummary"
              name="patientSummary"
              value={formData.patientSummary}
              onChange={handleInputChange}
              placeholder="e.g., 65M with 3-week fever, weight loss, and new lung mass"
            />
          </div>
          
          <div className={styles.formSection}>
            <h4>Relevant Clinical History</h4>
            
            <div className={styles.formField}>
              <label htmlFor="medicalHistory">Past Medical & Surgical History</label>
              <textarea
                id="medicalHistory"
                name="medicalHistory"
                value={formData.medicalHistory}
                onChange={handleInputChange}
                placeholder="Relevant PMHx (chronic conditions, immunosuppression, etc.)"
                rows={2}
              />
            </div>
            
            <div className={styles.formField}>
              <label htmlFor="socialHistory">Social & Oncological History</label>
              <textarea
                id="socialHistory"
                name="socialHistory"
                value={formData.socialHistory}
                onChange={handleInputChange}
                placeholder="Lifestyle factors, occupational exposure, cancer history"
                rows={2}
              />
            </div>
            
            <div className={styles.formField}>
              <label htmlFor="relevantMedications">Relevant Medications</label>
              <textarea
                id="relevantMedications"
                name="relevantMedications"
                value={formData.relevantMedications}
                onChange={handleInputChange}
                placeholder="Medication started recently & reason for relevance"
                rows={2}
              />
            </div>
            
            <div className={styles.formField}>
              <label htmlFor="relevantSymptoms">Relevant Symptoms</label>
              <textarea
                id="relevantSymptoms"
                name="relevantSymptoms"
                value={formData.relevantSymptoms}
                onChange={handleInputChange}
                placeholder="Supporting symptoms, contradictory symptoms, atypical features"
                rows={2}
              />
            </div>
          </div>
        </div>
        
        {/* Objective Container */}
        <div className={styles.container}>
          <h3>Objective Data</h3>
          
          <div className={styles.formField}>
            <label htmlFor="objectiveData">Key Findings, Labs, Imaging Results</label>
            <textarea
              id="objectiveData"
              name="objectiveData"
              value={formData.objectiveData}
              onChange={handleInputChange}
              placeholder="Enter vitals, labs, imaging findings, microbiology, etc. Include both key positives and key negatives."
              rows={6}
            />
          </div>
        </div>
        
        {/* Gestalt Container */}
        <div className={styles.container}>
          <h3>🧠 Moose Gestalt Analysis</h3>
          
          {/* Clinical Intent Section */}
          <div className={styles.formSection}>
            <h4>🎯 Clinical Intent & Questions</h4>
            
            <div className={styles.formField}>
              <label htmlFor="clinicalQuestion">Primary Clinical Question</label>
              <textarea
                id="clinicalQuestion"
                name="clinicalQuestion"
                value={formData.clinicalQuestion}
                onChange={handleInputChange}
                placeholder="e.g., Is this presentation consistent with pneumonia?"
                rows={2}
              />
            </div>
            
            <div className={styles.formField}>
              <label>Presentation Archetype</label>
              <div className={styles.radioGroup}>
                <label>
                  <input
                    type="radio"
                    name="presentationArchetype"
                    value="classic_common"
                    checked={formData.presentationArchetype === 'classic_common'}
                    onChange={handleInputChange}
                  />
                  Classic presentation of common condition
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="presentationArchetype"
                    value="atypical_common"
                    checked={formData.presentationArchetype === 'atypical_common'}
                    onChange={handleInputChange}
                  />
                  Atypical presentation of common condition
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="presentationArchetype"
                    value="classic_rare"
                    checked={formData.presentationArchetype === 'classic_rare'}
                    onChange={handleInputChange}
                  />
                  Classic presentation of rare condition
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="presentationArchetype"
                    value="mystery"
                    checked={formData.presentationArchetype === 'mystery'}
                    onChange={handleInputChange}
                  />
                  Mystery case with no clear pattern
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="presentationArchetype"
                    value="multi_system"
                    checked={formData.presentationArchetype === 'multi_system'}
                    onChange={handleInputChange}
                  />
                  Multi-system complexity
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="presentationArchetype"
                    value="diagnostic_impasse"
                    checked={formData.presentationArchetype === 'diagnostic_impasse'}
                    onChange={handleInputChange}
                  />
                  Diagnostic impasse after thorough workup
                </label>
              </div>
            </div>
            
            <div className={styles.formField}>
              <label>Intent for AI Analysis</label>
              <div className={styles.checkboxGroup}>
                <label>
                  <input
                    type="checkbox"
                    name="clinicalIntent"
                    value="confirm_diagnosis"
                    checked={formData.clinicalIntent.includes('confirm_diagnosis')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Confirm my leading diagnosis
                </label>
                
                <label>
                  <input
                    type="checkbox"
                    name="clinicalIntent"
                    value="challenge_diagnosis"
                    checked={formData.clinicalIntent.includes('challenge_diagnosis')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Challenge my diagnosis
                </label>
                
                <label>
                  <input
                    type="checkbox"
                    name="clinicalIntent"
                    value="expand_differentials"
                    checked={formData.clinicalIntent.includes('expand_differentials')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Expand differential diagnoses
                </label>
                
                <label>
                  <input
                    type="checkbox"
                    name="clinicalIntent"
                    value="analyze_without_bias"
                    checked={formData.clinicalIntent.includes('analyze_without_bias')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Analyze imaging findings without bias
                </label>
                
                <label>
                  <input
                    type="checkbox"
                    name="clinicalIntent"
                    value="explain_unexpected"
                    checked={formData.clinicalIntent.includes('explain_unexpected')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Explain unexpected findings
                </label>
                
                <label>
                  <input
                    type="checkbox"
                    name="clinicalIntent"
                    value="compare_patterns"
                    checked={formData.clinicalIntent.includes('compare_patterns')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Compare with classic imaging patterns
                </label>
              </div>
            </div>
          </div>
          
          {/* Timelines Section */}
          <div className={styles.formSection}>
            <h4>📆 Timelines & Evolution</h4>
            
            <div className={styles.formField}>
              <label htmlFor="imagingTimeline">Imaging Timeline</label>
              <textarea
                id="imagingTimeline"
                name="imagingTimeline"
                value={formData.imagingTimeline}
                onChange={handleInputChange}
                placeholder="e.g., normal CXR 1 year ago, new mass now"
                rows={2}
              />
            </div>
            
            <div className={styles.formField}>
              <label htmlFor="symptomTimeline">Symptom Timeline</label>
              <textarea
                id="symptomTimeline"
                name="symptomTimeline"
                value={formData.symptomTimeline}
                onChange={handleInputChange}
                placeholder="Onset, chronic vs acute, intermittent, improving or worsening"
                rows={2}
              />
            </div>
            
            <div className={styles.formField}>
              <label htmlFor="temporalAnalysis">Temporal Analysis & Treatment Response</label>
              <textarea
                id="temporalAnalysis"
                name="temporalAnalysis"
                value={formData.temporalAnalysis}
                onChange={handleInputChange}
                placeholder="e.g., worsened despite antibiotics"
                rows={2}
              />
            </div>
          </div>
          
          {/* Contradictions Section */}
          <div className={styles.formSection}>
            <h4>🚨 Major Contradictions & Red Flags</h4>
            
            <div className={styles.formField}>
              <label htmlFor="majorContradictions">Contradictions</label>
              <textarea
                id="majorContradictions"
                name="majorContradictions"
                value={formData.majorContradictions}
                onChange={handleInputChange}
                placeholder="Findings that don't make sense clinically"
                rows={2}
              />
            </div>
            
            <div className={styles.formField}>
              <label>Red Flags</label>
              <div className={styles.checkboxGroup}>
                <label>
                  <input
                    type="checkbox"
                    name="redFlags"
                    value="hemodynamic_instability"
                    checked={formData.redFlags.includes('hemodynamic_instability')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Hemodynamic instability
                </label>
                
                <label>
                  <input
                    type="checkbox"
                    name="redFlags"
                    value="neurologic_deficits"
                    checked={formData.redFlags.includes('neurologic_deficits')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Neurologic deficits
                </label>
                
                <label>
                  <input
                    type="checkbox"
                    name="redFlags"
                    value="immunocompromised"
                    checked={formData.redFlags.includes('immunocompromised')}
                    onChange={handleCheckboxArrayChange}
                  />
                  Immunocompromised status
                </label>
              </div>
            </div>
          </div>
          
          {/* Diagnostic Hypothesis Section */}
          <div className={styles.formSection}>
            <h4>🤔 Clinician Cognitive Context</h4>
            
            <div className={styles.formField}>
              <label>Diagnostic Hypothesis State</label>
              <div className={styles.radioGroup}>
                <label>
                  <input
                    type="radio"
                    name="diagnosticState"
                    value="confirmed"
                    checked={formData.diagnosticState === 'confirmed'}
                    onChange={handleInputChange}
                  />
                  I have a confirmed diagnosis related to imaging findings
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="diagnosticState"
                    value="possible"
                    checked={formData.diagnosticState === 'possible'}
                    onChange={handleInputChange}
                  />
                  I have a possible diagnosis related to imaging findings
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="diagnosticState"
                    value="none"
                    checked={formData.diagnosticState === 'none'}
                    onChange={handleInputChange}
                  />
                  I have no diagnosis related to imaging findings
                </label>
              </div>
            </div>
            
            {formData.diagnosticState && formData.diagnosticState !== 'none' && (
              <>
                <div className={styles.formField}>
                  <label htmlFor="primaryHypothesis">Primary Hypothesis</label>
                  <div className={styles.inputWithSlider}>
                    <input
                      type="text"
                      id="primaryHypothesis"
                      name="primaryHypothesis"
                      value={formData.primaryHypothesis}
                      onChange={handleInputChange}
                      placeholder="e.g., Community-acquired pneumonia"
                    />
                    <div className={styles.confidenceSlider}>
                      <label htmlFor="diagnosticConfidence">Confidence: {formData.diagnosticConfidence}%</label>
                      <input
                        type="range"
                        id="diagnosticConfidence"
                        name="diagnosticConfidence"
                        min="0"
                        max="100"
                        value={formData.diagnosticConfidence}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                
                <div className={styles.formField}>
                  <label htmlFor="supportingFactors">Supporting Factors</label>
                  <textarea
                    id="supportingFactors"
                    name="supportingFactors"
                    value={formData.supportingFactors}
                    onChange={handleInputChange}
                    placeholder="e.g., fever, infiltrates, leukocytosis"
                    rows={2}
                  />
                </div>
                
                <div className={styles.formField}>
                  <label htmlFor="contradictingFactors">Contradicting Factors</label>
                  <textarea
                    id="contradictingFactors"
                    name="contradictingFactors"
                    value={formData.contradictingFactors}
                    onChange={handleInputChange}
                    placeholder="e.g., normal procalcitonin"
                    rows={2}
                  />
                </div>
              </>
            )}
          </div>
          
          {/* Strategic Challenge Section */}
          <div className={styles.formSection}>
            <h4>⚠️ Strategic Diagnostic Challenge</h4>
            
            <div className={styles.formField}>
              <label>Critique Level Desired</label>
              <div className={styles.radioGroup}>
                <label>
                  <input
                    type="radio"
                    name="critiqueLevel"
                    value="gentle"
                    checked={formData.critiqueLevel === 'gentle'}
                    onChange={handleInputChange}
                  />
                  Gentle confirmation
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="critiqueLevel"
                    value="forceful"
                    checked={formData.critiqueLevel === 'forceful'}
                    onChange={handleInputChange}
                  />
                  Forceful contradiction
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="critiqueLevel"
                    value="alternative"
                    checked={formData.critiqueLevel === 'alternative'}
                    onChange={handleInputChange}
                  />
                  Alternative pathway exploration
                </label>
                
                <label>
                  <input
                    type="radio"
                    name="critiqueLevel"
                    value="balanced"
                    checked={formData.critiqueLevel === 'balanced'}
                    onChange={handleInputChange}
                  />
                  Balanced assessment
                </label>
              </div>
            </div>
            
            <div className={styles.formField}>
              <label htmlFor="vulnerabilityTesting">Specific Vulnerability Testing</label>
              <textarea
                id="vulnerabilityTesting"
                name="vulnerabilityTesting"
                value={formData.vulnerabilityTesting}
                onChange={handleInputChange}
                placeholder="e.g., Could this be aspiration rather than CAP?"
                rows={2}
              />
            </div>
          </div>
          
          {/* Emergency & Specialty Modes */}
          <div className={styles.formSection}>
            <h4>🛟 Emergency & Specialty Modes</h4>
            
            <div className={styles.checkbox}>
              <label>
                <input
                  type="checkbox"
                  name="emergencyMode"
                  checked={formData.emergencyMode}
                  onChange={handleInputChange}
                />
                Emergency Mode (prioritize urgent findings & management)
              </label>
            </div>
            
            <div className={styles.checkbox}>
              <label>
                <input
                  type="checkbox"
                  name="oncologyMode"
                  checked={formData.oncologyMode}
                  onChange={handleInputChange}
                />
                Oncology Mode (focus on staging, prognostic markers)
              </label>
            </div>
          </div>
        </div>
        
        <div className={styles.previewSection}>
          <h3>Generated Prompt Preview</h3>
          <div className={styles.promptPreview}>
            <pre>{generateStructuredPrompt()}</pre>
          </div>
        </div>
        
        <div className={styles.formActions}>
          <button type="submit" className={styles.submitButton}>
            Save Configuration
          </button>
        </div>
      </form>
    </div>
  );
}

// Set displayName for component identification
AdvancedDiagnostics.displayName = 'AdvancedDiagnostics';

export default AdvancedDiagnostics;
