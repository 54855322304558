// --- Corrected App.js ---

import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Components
import { MainLayout } from './components/layout/MainLayout'; // Keep this
// Remove imports for components now rendered BY MainLayout or CanvasViewManager:
// import { LeftPanel } from './components/layout/LeftPanel';
// import { CanvasViewManager } from './components/canvas/CanvasViewManager';
// import DocumentationView from './components/canvas/DocumentationView';
// import TabNavigation from './components/canvas/TabNavigation';
// import { RightPanel } from './components/layout/RightPanel';
// import { Header } from './components/layout/Header';
import ChatInput from './components/common/ChatInput'; // Keep if used elsewhere (seems only used for the deleted footer prop)
import LoadingOverlay from './components/common/LoadingOverlay'; // Keep
import LoginModal from './components/auth/LoginModal'; // Keep
import AdminPanel from './components/admin/AdminPanel'; // Keep
import ProtectedRoute from './components/common/ProtectedRoute'; // Keep
import MobileNotice from './components/layout/MobileNotice'; // Keep

// Contexts and Services
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { analyzeImage, sendQuery, checkHealth } from './services/api';

// Custom Hooks
import useImageAnalysis from './hooks/useImageAnalysis';
import useReportHistory from './hooks/useReportHistory';
import useAppSettings from './hooks/useAppSettings';

// Utilities
import { getAssetPath } from './utils/assetUtils';

function App({ config, api }) {
  // Default config if not provided (for standalone mode)
  const appConfig = config || {
    apiEndpoint: 'https://0an6vk4faowbh2-3000.proxy.runpod.net',
    basePath: ''
  };

  // REMOVED: State for active tab - now managed in CanvasViewManager
  // const [activeTab, setActiveTab] = useState('image');

  // State for login modal
  const [showLoginModal, setShowLoginModal] = useState(false);

  // Use component API if provided, wrapped in useMemo to prevent recreation on every render
  const apiService = useMemo(() => {
    return api || {
      analyzeImage: (imageData, endpoint, prompt) => analyzeImage(imageData, endpoint, prompt),
      sendQuery: (query) => sendQuery(query, appConfig.apiEndpoint),
      checkHealth: () => checkHealth(appConfig.apiEndpoint)
    };
  }, [api, appConfig.apiEndpoint]);

  // Custom hooks for different functionality
  const { isMobile } = useAppSettings({
    checkHealthFunction: apiService.checkHealth
  });

  const {
    hasImage, imageSrc, viewState, isLoading, showResults, analysisResults,
    handleImageUpload, handleViewInCanvas, handleAnalyze,
    setShowResults, setAnalysisResults
  } = useImageAnalysis({
    apiService,
    apiEndpoint: appConfig.apiEndpoint
  });

  const { reportHistory, loadingHistory, fetchReportHistory } = useReportHistory();

  // Function to properly format asset paths
  const getAssetPathFn = (path) => getAssetPath(path, appConfig.basePath);

  // Load a report from history - NOTE: This logic might need adjustment
  // as setActiveTab is gone. Maybe pass a function to CanvasViewManager via MainLayout
  // to imperatively set the tab if needed, or handle differently.
  // For now, just setting the data.
  const loadReportFromHistory = (reportItem) => {
    setAnalysisResults(reportItem.data);
    setShowResults(true);
    // setActiveTab('documentation'); // REMOVED - CanvasViewManager controls this
    // Consider how selecting history should trigger the documentation view now.
  };

  // Update report history when analysis is completed
  useEffect(() => {
    if (showResults && analysisResults) {
      fetchReportHistory();
    }
  }, [showResults, analysisResults, fetchReportHistory]);

  // Main application UI
  const MainApp = () => {
    const { 
      user: authUser, 
      profile, 
      loading: authLoading, 
      isAdmin: authIsAdmin, 
      logout 
    } = useAuth(); // Get user data from context

    // Show mobile notice if on a mobile device
    if (isMobile) {
      return <MobileNotice getAssetPath={getAssetPathFn} />;
    }

    // REMOVED: mainContent variable and its conditional logic

    // Only create user object for authenticated users
    const currentUser = authUser 
      ? { name: profile?.full_name || authUser.email, email: authUser.email, avatar: profile?.avatar_url } 
      : null;


    return (
      <>
        {/* Show auth loading overlay if still loading auth */}
        {authLoading && <LoadingOverlay isLoading={true} loadingText="Loading your profile..." />}
        
        {/* Central Loading Overlay for analysis loading */}
        <LoadingOverlay isLoading={isLoading} />

        {/* Always render MainLayout, even if auth is still loading */}
        <MainLayout
          // Props for LeftPanel
          leftPanelProps={{
            onImageUpload: handleImageUpload,
            // onViewInCanvas: handleViewInCanvas, // This likely belongs inside CanvasViewManager now
            onAnalyze: handleAnalyze,
            hasImage: hasImage,
            imageSrc: imageSrc, // Left panel might need preview?
            getAssetPath: getAssetPathFn
          }}
          // Props for RightPanel
          rightPanelProps={{
            reportHistory: reportHistory,
            loadingHistory: loadingHistory,
            onSelectReport: loadReportFromHistory // Pass history selection handler
          }}
          // Props for CanvasViewManager (passed through MainLayout)
          imageSrc={imageSrc}
          analysisResults={analysisResults}
          showResults={showResults}
          isLoading={isLoading} // Pass analysis loading state
          // Props for Header (passed through MainLayout)
          user={currentUser}
          isAdmin={authIsAdmin}
          onLogout={logout}
          onShowLoginModal={() => setShowLoginModal(true)}
          // REMOVED: activeTab - managed internally by CanvasViewManager
          // REMOVED: onTabChange - managed internally by CanvasViewManager
          // REMOVED: footer prop - MainLayout renders Footer directly
        >
          {/* REMOVED: {mainContent} - MainLayout renders CanvasViewManager internally */}
        </MainLayout>
      </>
    );
  };

  return (
    <AuthProvider>
      <Router>
        {/* Login Modal */}
        <LoginModal
          isOpen={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        />

        <Routes>
          {/* Admin route requires authentication with admin privileges */}
          <Route path="/admin" element={
            <ProtectedRoute adminOnly={true}>
              <AdminPanel />
            </ProtectedRoute>
          } />
          {/* Main route is accessible to everyone */}
          <Route path="/" element={<MainApp />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
