import React from 'react';
import styles from './RightPanel.module.css';

export function RightPanel({ 
  reportHistory = [], 
  loadingHistory = false,
  onSelectReport
}) {
  // Get current time for reports without timestamps
  const currentTime = new Date().toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
  
  return (
    <div className={styles.rightPanel}>
      <div className={styles.contentSection}>
        <h2 className={styles.sectionHeader}>History</h2>
        
        <div className={styles.recentReportsSection}>
          {loadingHistory ? (
            <div className={styles.loadingHistory}>
              Loading report history...
            </div>
          ) : reportHistory.length > 0 ? (
            <div className={styles.reportsList}>
              {reportHistory.map(report => (
                <div 
                  key={report.id} 
                  className={styles.reportItem} 
                  onClick={() => onSelectReport(report)}
                >
                  <div className={styles.reportItemContent}>
                    <div className={styles.reportTitle}>{report.title || 'Medical Analysis'}</div>
                    <div className={styles.reportTimestamp}>
                      {report.date} • {currentTime}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.emptyHistory}>
              No report history available
              <div className={styles.emptyHistoryHint}>Reports will appear here after analysis</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Named export for better import/export consistency
export default RightPanel;
