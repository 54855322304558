import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import LoadingOverlay from '../LoadingOverlay';

/**
 * A wrapper component that shows a login modal or redirects if the user is not authenticated
 */
const ProtectedRoute = ({ children, adminOnly = false, onAuthRequired }) => {
  const { user, profile, loading, isAuthenticated, researchKeyAccess } = useAuth();

  useEffect(() => {
    // If authentication is required and the user is not authenticated, trigger the callback
    if (!loading && !isAuthenticated && onAuthRequired) {
      onAuthRequired();
    }
  }, [loading, isAuthenticated, onAuthRequired]);

  // Show loading overlay while checking authentication status
  if (loading) {
    return <LoadingOverlay message="Checking authentication..." />;
  }

  // Check for admin access if required
  if (adminOnly && (!user || !profile?.is_admin)) {
    return <Navigate to="/" replace />;
  }

  // For non-admin routes, allow access if user is authenticated or has research key access
  if (!isAuthenticated) {
    // If onAuthRequired is provided, we'll show the children and let the modal handle authentication
    if (onAuthRequired) {
      return children;
    }
    // Otherwise, redirect to home
    return <Navigate to="/" replace />;
  }

  // We've removed the separate research key banner since it's now in the header
  return children;
};

export default ProtectedRoute;
