import React from 'react';
import ReactDOM from 'react-dom/client';

// Import global styles
import './styles/base/reset.css';
import './styles/theme/variables.css';
import './styles/base/global.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

// Check if we're in module mode
const isModule = window.MOOSE_MODULE === true;

// Regular standalone mode
if (!isModule) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    // Temporarily disable StrictMode to debug rendering issues
    <App />
  );
  reportWebVitals();
} else {
  // Module mode - expose init function
  window.ProjectMoose = {
    init: (container, config, api) => {
      const root = ReactDOM.createRoot(container);
      root.render(
        // Temporarily disable StrictMode to debug rendering issues
        <App config={config} api={api} />
      );
    }
  };
}

// For direct use in our module build
// Commented out for standalone development mode
// if (typeof window !== 'undefined') {
//   window.MOOSE_MODULE = true;
// }
