import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile as firebaseUpdateProfile
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  getDoc, 
  collection, 
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../services/firebase';

// Create the authentication context
export const AuthContext = createContext();

// Session storage key for temporary research key access
const RESEARCH_KEY_SESSION_KEY = 'moose_research_key_access';

// AuthProvider component to wrap around our app
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [researchKeyAccess, setResearchKeyAccess] = useState(
    sessionStorage.getItem(RESEARCH_KEY_SESSION_KEY) || null
  );

  // Set up auth state listener with timeout fallback
  useEffect(() => {
    let authStateTimeout;
    let unsubscribe = () => {};
    
    try {
      // Set a safety timeout to ensure we don't get stuck in loading state
      authStateTimeout = setTimeout(() => {
        console.warn("Firebase auth state observer timed out - continuing as guest");
        setUser(null);
        setProfile(null);
        setLoading(false);
      }, 5000); // 5 second timeout
      
      unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
        clearTimeout(authStateTimeout);
        setUser(currentUser);
        
        if (currentUser) {
          // Fetch user profile from Firestore
          try {
            const userDocRef = doc(db, 'users', currentUser.uid);
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
              setProfile(userDoc.data());
            } else {
              // Create default profile if it doesn't exist
              const defaultProfile = {
                uid: currentUser.uid,
                email: currentUser.email,
                displayName: currentUser.displayName || '',
                photoURL: currentUser.photoURL || '',
                role: 'user',
                createdAt: serverTimestamp()
              };
              
              await setDoc(userDocRef, defaultProfile);
              setProfile(defaultProfile);
            }
          } catch (error) {
            console.error("Error fetching user profile:", error);
            // Continue without profile data
          }
        } else {
          setProfile(null);
        }
        
        setLoading(false);
      });
    } catch (error) {
      // Handle any Firebase initialization errors
      console.error("Firebase auth initialization error:", error);
      clearTimeout(authStateTimeout);
      setUser(null);
      setProfile(null);
      setLoading(false);
    }
    
    // Cleanup function
    return () => {
      clearTimeout(authStateTimeout);
      unsubscribe();
    };
  }, []);

  // Helper function to generate a random key (preserved from original)
  const generateRandomKey = (length = 12) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  // Sign up with email and password
  const signUpWithEmail = async (email, password, displayName) => {
    try {
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Update profile with display name
      if (displayName) {
        await firebaseUpdateProfile(userCredential.user, { displayName });
      }
      
      // Create user document in Firestore
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userDocRef, {
        uid: userCredential.user.uid,
        email,
        displayName: displayName || '',
        photoURL: '',
        role: 'user',
        createdAt: serverTimestamp()
      });
      
      return { user: userCredential.user };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Sign in with email and password
  const signInWithEmail = async (email, password) => {
    try {
      setLoading(true);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return { user: userCredential.user };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Sign out
  const signOut = async () => {
    try {
      setLoading(true);
      await firebaseSignOut(auth);
      
      // Clear all session storage related to auth
      sessionStorage.removeItem(RESEARCH_KEY_SESSION_KEY);
      sessionStorage.removeItem('moose_research_user_id');
      
      // Reset state
      setResearchKeyAccess(null);
      setProfile(null);
      setUser(null);
      
      return { success: true };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Update user profile
  const updateProfile = async (data) => {
    try {
      setLoading(true);
      
      if (!user) {
        throw new Error('No authenticated user found');
      }
      
      // Update Firebase auth profile if display name or photo URL are provided
      const authUpdates = {};
      if (data.displayName) authUpdates.displayName = data.displayName;
      if (data.photoURL) authUpdates.photoURL = data.photoURL;
      
      if (Object.keys(authUpdates).length > 0) {
        await firebaseUpdateProfile(user, authUpdates);
      }
      
      // Update profile in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const updatedProfile = { ...data, updatedAt: serverTimestamp() };
      await setDoc(userDocRef, updatedProfile, { merge: true });
      
      // Update local profile state
      setProfile(prev => ({ ...prev, ...updatedProfile }));
      
      return { success: true };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Upload profile image
  const uploadImage = async (file) => {
    try {
      setLoading(true);
      
      if (!user) {
        throw new Error('No authenticated user found');
      }
      
      const storageRef = ref(storage, `users/${user.uid}/profile-image`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      
      // Update user profile with new photo URL
      await updateProfile({ photoURL: downloadURL });
      
      return { downloadURL };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Research key validation
  const validateResearchKey = async (key) => {
    try {
      setLoading(true);
      
      const keysRef = collection(db, 'research_keys');
      const q = query(keysRef, where('key_value', '==', key), where('is_active', '==', true));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const keyDoc = querySnapshot.docs[0];
        const keyData = keyDoc.data();
        
        // Check if key has expired
        if (keyData.expires_at && new Date(keyData.expires_at.toDate()) < new Date()) {
          return { error: 'This research key has expired' };
        }
        
        // Check if key has uses remaining
        if (keyData.max_uses !== null && keyData.uses_remaining <= 0) {
          return { error: 'This research key has reached its usage limit' };
        }
        
        // Key is valid, store in session
        sessionStorage.setItem(RESEARCH_KEY_SESSION_KEY, key);
        setResearchKeyAccess(key);
        
        // Return key data for user profile setup
        return { 
          success: true, 
          keyData: {
            id: keyDoc.id,
            ...keyData
          }
        };
      }
      
      return { error: 'Invalid research key' };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Create research key with advanced options
  const createResearchKey = async (keyData = {}) => {
    try {
      setLoading(true);
      
      if (!user || (profile && profile.role !== 'admin')) {
        throw new Error('Unauthorized access');
      }
      
      const keyValue = keyData.key_value || `${keyData.prefix || 'MOOSE-KEY'}-${generateRandomKey(8)}`;
      
      const researchKey = {
        key_value: keyValue,
        name: keyData.name || '',
        prefix: keyData.prefix || 'MOOSE-KEY',
        max_uses: keyData.max_uses || null,
        uses_remaining: keyData.max_uses || null,
        duration: keyData.duration || null,
        expires_at: keyData.expires_at || null,
        is_active: true,
        created_at: serverTimestamp(),
        created_by: user.uid,
        description: keyData.description || ''
      };
      
      const docRef = await addDoc(collection(db, 'research_keys'), researchKey);
      
      return { 
        id: docRef.id,
        ...researchKey
      };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Batch create research keys
  const batchCreateResearchKeys = async (count = 10) => {
    try {
      setLoading(true);
      
      if (!user || (profile && profile.role !== 'admin')) {
        throw new Error('Unauthorized access');
      }
      
      const keys = [];
      for (let i = 0; i < count; i++) {
        const key = generateRandomKey();
        keys.push(key);
        
        await addDoc(collection(db, 'research_keys'), {
          key,
          active: true,
          createdAt: serverTimestamp(),
          createdBy: user.uid
        });
      }
      
      return { keys };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Get research keys
  const getResearchKeys = async () => {
    try {
      setLoading(true);
      
      if (!user || (profile && profile.role !== 'admin')) {
        throw new Error('Unauthorized access');
      }
      
      const keysRef = collection(db, 'research_keys');
      const querySnapshot = await getDocs(keysRef);
      const keys = [];
      
      querySnapshot.forEach((doc) => {
        keys.push({ id: doc.id, ...doc.data() });
      });
      
      return { keys };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Get all users (admin only)
  const getAllUsers = async () => {
    try {
      setLoading(true);
      
      if (!user || (profile && profile.role !== 'admin')) {
        throw new Error('Unauthorized access');
      }
      
      const usersRef = collection(db, 'users');
      const querySnapshot = await getDocs(usersRef);
      const users = [];
      
      querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });
      
      return { users };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Get user reports
  const getUserReports = async (userId = null) => {
    try {
      setLoading(true);
      
      if (!user && !researchKeyAccess) {
        throw new Error('Unauthorized access');
      }
      
      const targetUserId = userId || user.uid;
      
      const reportsRef = collection(db, 'reports');
      const q = query(reportsRef, where('userId', '==', targetUserId));
      const querySnapshot = await getDocs(q);
      const reports = [];
      
      querySnapshot.forEach((doc) => {
        reports.push({ id: doc.id, ...doc.data() });
      });
      
      return { reports };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Create report
  const createReport = async (reportData) => {
    try {
      setLoading(true);
      
      if (!user && !researchKeyAccess) {
        throw new Error('Unauthorized access');
      }
      
      const reportWithMetadata = {
        ...reportData,
        userId: user ? user.uid : 'anonymous',
        createdAt: serverTimestamp(),
        researchKey: researchKeyAccess
      };
      
      const docRef = await addDoc(collection(db, 'reports'), reportWithMetadata);
      
      // If using a research key, decrement uses_remaining
      if (researchKeyAccess) {
        const keysRef = collection(db, 'research_keys');
        const q = query(keysRef, where('key_value', '==', researchKeyAccess));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const keyDoc = querySnapshot.docs[0];
          const keyData = keyDoc.data();
          
          if (keyData.max_uses !== null) {
            await setDoc(doc(db, 'research_keys', keyDoc.id), {
              uses_remaining: (keyData.uses_remaining || keyData.max_uses) - 1,
              last_used_at: serverTimestamp()
            }, { merge: true });
          } else {
            // Just update last used
            await setDoc(doc(db, 'research_keys', keyDoc.id), {
              last_used_at: serverTimestamp()
            }, { merge: true });
          }
        }
      }
      
      return { reportId: docRef.id };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };

  // Create user profile from research key
  const createUserFromResearchKey = async (keyId, userData) => {
    try {
      setLoading(true);
      
      if (!researchKeyAccess) {
        throw new Error('No research key access');
      }
      
      // Create anonymous user account for the research key user
      const userDocRef = doc(collection(db, 'users'));
      
      // Set user data with profile information
      const userProfile = {
        uid: userDocRef.id,
        displayName: `${userData.prefix || ''} ${userData.firstName} ${userData.lastName}`,
        firstName: userData.firstName,
        lastName: userData.lastName,
        prefix: userData.prefix,
        specialty: userData.specialty,
        hospital: userData.hospital || userData.university,
        year: userData.year,
        role: 'researcher', // Default role for research key users
        createdAt: serverTimestamp(),
        researchKey: researchKeyAccess,
        keyId: keyId,
        isResearchUser: true,
      };
      
      await setDoc(userDocRef, userProfile);
      
      // Store user ID in session storage to maintain persistence
      sessionStorage.setItem('moose_research_user_id', userDocRef.id);
      
      // Also update the profile state
      setProfile(userProfile);
      
      return { success: true, profile: userProfile };
    } catch (error) {
      return { error };
    } finally {
      setLoading(false);
    }
  };
  
  // Get research user from session if exists
  const getResearchUser = async () => {
    try {
      const researchUserId = sessionStorage.getItem('moose_research_user_id');
      if (!researchUserId) return { profile: null };
      
      const userDocRef = doc(db, 'users', researchUserId);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists() && userDoc.data().isResearchUser) {
        const userProfile = userDoc.data();
        setProfile(userProfile);
        return { profile: userProfile };
      }
      
      return { profile: null };
    } catch (error) {
      return { error };
    }
  };

  // Provide the auth context value to children
  const value = {
    user,
    profile,
    researchKeyAccess,
    signInWithEmail,
    signUpWithEmail,
    signOut,
    updateProfile,
    validateResearchKey,
    createResearchKey,
    batchCreateResearchKeys,
    getResearchKeys,
    getAllUsers,
    getUserReports,
    createReport,
    uploadImage,
    loading,
    isAuthenticated: !!user,
    createUserFromResearchKey,
    getResearchUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Custom hook to use the auth context
export function useAuth() {
  return useContext(AuthContext);
}
