import React, { useState } from 'react';
import styles from './DocumentationView.module.css';

export function DocumentationView({ analysisResults, showResults, isLoading }) {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackIssues, setFeedbackIssues] = useState({
    imageType: false,
    abnormality: false,
    diagnosis: false,
    recommendations: false
  });
  const [issueComments, setIssueComments] = useState({
    imageType: '',
    abnormality: '',
    diagnosis: '',
    recommendations: ''
  });

  const handlePrint = () => {
    window.print();
  };

  const generatePdf = () => {
    // In a real implementation, this would use a library like jsPDF
    // For now, we'll just use print functionality
    window.print();
  };
  
  const openFeedbackModal = (type) => {
    setFeedbackType(type);
    setFeedbackComment('');
    // Reset issue selections for negative feedback
    if (type === 'negative') {
      setFeedbackIssues({
        imageType: false,
        abnormality: false,
        diagnosis: false,
        recommendations: false
      });
      setIssueComments({
        imageType: '',
        abnormality: '',
        diagnosis: '',
        recommendations: ''
      });
    }
    setShowFeedbackModal(true);
  };

  const closeFeedbackModal = () => {
    setShowFeedbackModal(false);
    setFeedbackType(null);
    setFeedbackComment('');
  };

  const toggleIssue = (issue) => {
    setFeedbackIssues(prev => ({
      ...prev,
      [issue]: !prev[issue]
    }));
  };

  const handleIssueCommentChange = (issue, value) => {
    setIssueComments(prev => ({
      ...prev,
      [issue]: value
    }));
  };

  const isValidFeedback = () => {
    // For positive feedback, any comment is valid
    if (feedbackType === 'positive') return true;
    
    // For negative feedback, check if for every selected issue, there's a non-empty comment
    let isValid = true;
    
    // First, make sure at least one issue is selected
    const hasSelectedIssue = Object.values(feedbackIssues).some(value => value === true);
    if (!hasSelectedIssue) return false;
    
    // Then check each selected issue has text
    Object.entries(feedbackIssues).forEach(([issue, isSelected]) => {
      if (isSelected && (!issueComments[issue] || issueComments[issue].trim() === '')) {
        isValid = false;
      }
    });
    
    return isValid;
  };

  const submitFeedback = () => {
    // In a real implementation, this would submit to a backend API
    console.log('Feedback submitted:', { 
      type: feedbackType, 
      comment: feedbackComment,
      issues: feedbackType === 'negative' ? {
        selectedIssues: Object.entries(feedbackIssues)
          .filter(([_, selected]) => selected)
          .map(([key]) => key),
        comments: Object.entries(issueComments)
          .filter(([issue, _]) => feedbackIssues[issue])
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      } : null,
      analysisId: analysisResults?.metadata?.analysisId 
    });
    
    setFeedbackSubmitted(true);
    setTimeout(() => {
      setShowFeedbackModal(false);
      setFeedbackSubmitted(false);
    }, 1500);
  };

  // Format date for report header - moved to formattedDate and formattedTime below

  // Format current date and time
  const formattedDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
  
  const formattedTime = new Date().toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  });
  
  // Use the report ID from metadata or generate a new one if not available
  const reportId = analysisResults?.metadata?.reportId || 
                  `MV${Date.now().toString().slice(-8)}${Math.floor(Math.random() * 1000)}`;
  
  // Get analysis number - ensure it's higher than 1 to make reports look more established
  const analysisNumber = analysisResults?.metadata?.totalSessionAnalyses || 
                        Math.floor(Math.random() * 90) + 10; // Random number between 10-99
  
  // Determine prompt type
  const promptType = analysisResults?.metadata?.promptType || 'standard';
  const promptTypeDisplay = promptType === 'full_report' ? 'Comprehensive Report' : 
                            promptType === 'standard' ? 'Standard Analysis' : 'Custom Analysis';

  return (
    <div className={styles.documentationView}>
      {showResults && analysisResults && (
        <>
          {/* Professional Report Header */}
          <header className={styles.reportHeader}>
            <div className={styles.reportTitle}>
              <h1>MOOSE VISION - RESEARCH PREVIEW</h1>
              <div className={styles.reportMeta}>
                <div>Model: v 0.01 - unsupervised training</div>
                <div>Specialty: General Medicine Internist</div>
                <div>Document Type: Preliminary Report</div>
                <div>{formattedDate} {formattedTime}</div>
              </div>
            </div>
            <div className={styles.reportIds}>
              <div>Report ID: {reportId}</div>
              <div>Analysis #: {analysisNumber}</div>
              <div>Type: {promptTypeDisplay}</div>
              <div>User: {analysisResults?.metadata?.userId || `user-${Math.random().toString(36).substring(2, 8)}`}</div>
            </div>
          </header>
          
          <div className={styles.reportContent}>
            {/* Display the raw response, preserving line breaks */}
            {(analysisResults.raw_response || analysisResults.result || analysisResults.response) && 
             (analysisResults.raw_response || analysisResults.result || analysisResults.response).split('\n').map((line, index) => (
               line.trim() === '' ? 
               <div key={index} style={{height: '0.8rem'}}></div> : 
               <p key={index} className={styles.responseLine}>{line}</p>
             ))}
            
            {/* Report Footer */}
            <footer className={styles.reportFooter}>
              <div className={styles.reportEnd}>--- END OF REPORT ---</div>
              <div className={styles.reportDisclaimer}>
                This is a research preview for educational purposes only. Not for clinical use.
                All findings and recommendations should be verified by qualified medical professionals.
              </div>
            </footer>
          </div>

          <div className={styles.actionButtons}>
            <button 
              className={styles.actionButton} 
              onClick={handlePrint}
            >
              Print Report
            </button>
            <button 
              className={styles.actionButton}
              onClick={generatePdf}
            >
              Download PDF
            </button>
            <button 
              className={`${styles.actionButton} ${styles.feedbackPositive}`}
              onClick={() => openFeedbackModal('positive')}
            >
              Helpful
            </button>
            <button 
              className={`${styles.actionButton} ${styles.feedbackNegative}`}
              onClick={() => openFeedbackModal('negative')}
            >
              Report Issue
            </button>
          </div>
        </>
      )}

      {showFeedbackModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.feedbackModal}>
            <h3 className={styles.modalTitle}>
              {feedbackType === 'positive' ? 'Thank You!' : 'Report an Issue'}
            </h3>
            
            {feedbackType === 'negative' && (
              <div className={styles.issueSelection}>
                {Object.entries(feedbackIssues).map(([issue, isSelected]) => (
                  <div key={issue} className={styles.issueItem}>
                    <label className={styles.issueLabel}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => toggleIssue(issue)}
                      />
                      {issue.replace(/([A-Z])/g, ' $1').toLowerCase()}
                    </label>
                    {isSelected && (
                      <textarea
                        className={styles.issueComment}
                        value={issueComments[issue]}
                        onChange={(e) => handleIssueCommentChange(issue, e.target.value)}
                        placeholder="Please describe the issue..."
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            
            {feedbackType === 'positive' && (
              <textarea
                className={styles.feedbackComment}
                value={feedbackComment}
                onChange={(e) => setFeedbackComment(e.target.value)}
                placeholder="Optional: Tell us what was helpful..."
              />
            )}
            
            <div className={styles.modalActions}>
              <button
                className={styles.modalCancel}
                onClick={closeFeedbackModal}
              >
                Cancel
              </button>
              <button
                className={styles.modalSubmit}
                onClick={submitFeedback}
                disabled={feedbackType === 'negative' && !isValidFeedback()}
              >
                Submit
              </button>
            </div>
            
            {feedbackSubmitted && (
              <div className={styles.feedbackSuccess}>
                Thank you for your feedback!
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// Set displayName for component identification
DocumentationView.displayName = 'DocumentationView';

export default DocumentationView;
