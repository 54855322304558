/**
 * API service for connecting to the Beast Mode server
 * Supports both standalone and modular usage
 */

/**
 * Extract findings from the response text
 * @param {string} responseText - The full response text from the API
 * @returns {Array} Array of findings extracted from the response
 */
const extractFindings = (responseText) => {
  if (!responseText) return [];
  
  const findings = [];
  
  // Try to find a "Findings" section
  const findingsRegex = /findings:?|observations:?/i;
  const diagnosisRegex = /diagnosis:?|impression:?|assessment:?/i;
  
  // Split the text into lines
  const lines = responseText.split('\n');
  
  let inFindingsSection = false;
  let foundFindingsHeader = false;
  
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    
    // Skip empty lines
    if (!line) continue;
    
    // Check if we're entering the findings section
    if (findingsRegex.test(line)) {
      inFindingsSection = true;
      foundFindingsHeader = true;
      continue;
    }
    
    // Check if we're leaving the findings section
    if (inFindingsSection && diagnosisRegex.test(line)) {
      inFindingsSection = false;
      continue;
    }
    
    // If we're in the findings section, add the line as a finding
    if (inFindingsSection) {
      // Check if it's a bullet point or numbered item
      if (line.startsWith('-') || line.startsWith('•') || line.startsWith('*') || /^\d+\./.test(line)) {
        // Remove the bullet point or number
        const finding = line.replace(/^[-•*]\s*|^\d+\.\s*/, '').trim();
        if (finding) findings.push(finding);
      } else if (line.length > 10) {
        // If it's not a bullet point but a substantial line, add it as a finding
        findings.push(line);
      }
    }
  }
  
  // If we didn't find a findings section, try to extract findings from the whole text
  if (!foundFindingsHeader && findings.length === 0) {
    // Look for bullet points or numbered items in the entire text
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      if (!line) continue;
      
      if (line.startsWith('-') || line.startsWith('•') || line.startsWith('*') || /^\d+\./.test(line)) {
        const finding = line.replace(/^[-•*]\s*|^\d+\.\s*/, '').trim();
        if (finding) findings.push(finding);
      }
    }
    
    // If still no findings, take the first few non-empty lines as findings
    if (findings.length === 0) {
      let count = 0;
      for (let i = 0; i < lines.length && count < 3; i++) {
        const line = lines[i].trim();
        if (line && line.length > 10) {
          findings.push(line);
          count++;
        }
      }
    }
  }
  
  return findings;
};

const BEAST_MODE_BASE_URL = 'https://0an6vk4faowbh2-3000.proxy.runpod.net';

const getApiUrl = (customEndpoint) => {
  // If custom endpoint is provided (from module config), use it
  if (customEndpoint) return customEndpoint;
  
  // Check if we're in development mode
  const isDevelopment = process.env.NODE_ENV === 'development';
  
  if (isDevelopment) {
    // In development, use the direct URL for local proxy through setupProxy.js
    // This will still go through the proxy defined in setupProxy.js
    return '';
  } else {
    // In production, use the API proxy to avoid CORS issues
    return '/api'; // This will use Netlify's proxy
  }
};

/**
 * Check if the Beast Mode server is operational
 * @param {string} customEndpoint - Optional API endpoint override
 * @returns {Promise} Promise resolving to health status
 */
export const checkHealth = async (customEndpoint) => {
  const API_URL = getApiUrl();
  const endpoint = `${API_URL}/health`;
  console.log('Checking health at:', endpoint);
  try {
    const response = await fetch(endpoint);
    
    if (!response.ok) {
      throw new Error(`Health check failed: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Health check error:', error);
    throw error;
  }
};

/**
 * Analyzes an uploaded image
 * @param {string} imageData - Base64 encoded image data
 * @param {string} customEndpoint - Optional API endpoint override
 * @param {string} prompt - Optional prompt for analysis
 * @returns {Promise} Promise resolving to analysis results
 */
export const analyzeImage = async (imageData, customEndpoint, prompt) => {
  const API_URL = getApiUrl();
  const endpoint = `${API_URL}/analyze`;
  
  try {
    // Convert base64 image data to a Blob
    const base64Response = await fetch(imageData);
    const blob = await base64Response.blob();
    
    // Create FormData and append the image file
    const formData = new FormData();
    formData.append('file', blob, 'medical_image.jpg');
    
    // Add prompt if provided
    // The backend uses a default if not provided:
    // "Analyze this medical image in detail. Identify findings, potential diagnoses, and provide recommendations."
    if (prompt) {
      formData.append('prompt', prompt);
    }
    
    console.log('Sending analysis request to:', endpoint);
    const response = await fetch(endpoint, {
      method: 'POST',
      body: formData,
      // No Content-Type header needed as it's set automatically with boundary for multipart/form-data
    });
    
    // Handle error responses
    if (!response.ok) {
      let errorDetail = 'Unknown error';
      try {
        const errorJson = await response.json();
        errorDetail = errorJson.detail || errorJson.message || JSON.stringify(errorJson);
      } catch (e) {
        // If not JSON, try to get text
        try {
          errorDetail = await response.text();
        } catch (textError) {
          errorDetail = `Status ${response.status}`;
        }
      }
      console.error('API error response:', errorDetail);
      throw new Error(`API error: ${response.status} - ${errorDetail}`);
    }
    
    const data = await response.json();
    console.log('Analysis response:', data);
    
    // The backend returns a structured AnalysisResponse with:
    // - findings: List of medical findings
    // - diagnosis: List of potential diagnoses
    // - recommendations: List of recommended actions
    // - confidence: Confidence score (0-1)
    // - processing_time: Time taken for processing
    
    // Format the response to match our application's expected structure
    // with the new simplified API response format
    const responseText = data.response || "";
    
    // Log the complete response for debugging truncation issues
    console.log('Complete response length:', responseText.length);
    console.log('Response first 100 chars:', responseText.substring(0, 100));
    console.log('Response last 100 chars:', responseText.substring(responseText.length - 100));
    
    // Extract findings from the response text
    const findings = extractFindings(responseText);
    
    return {
      // Provide the full response as raw_response
      raw_response: responseText,
      // Also provide the response in the result field for compatibility
      result: responseText,
      // Extract findings for the results panel
      findings: findings,
      // Keep processing time
      processing_time: data.processing_time,
      // Provide the full response as analysis for backward compatibility
      analysis: responseText,
      // Add some default follow-up questions if not provided
      followupQuestions: ["What is the significance of these findings?", "What additional tests would you recommend?"]
    };
  } catch (error) {
    console.error('Error analyzing image:', error);
    // Rethrow the error to be handled by the component
    throw error;
  }
};

/**
 * Sends a follow-up query about the analyzed image
 * @param {string} query - The user's question text
 * @param {string} customEndpoint - Optional API endpoint override
 * @param {string} imageData - Optional base64 image data for context
 * @returns {Promise} Promise resolving to the AI response
 */
export const sendQuery = async (query, customEndpoint, imageData = null) => {
  const API_URL = getApiUrl();
  const endpoint = `${API_URL}/query`;
  
  try {
    console.log('Sending query to:', endpoint);
    
    // Prepare request body according to backend expectations
    const requestBody = {
      query: query
    };
    
    // Add image data if provided
    if (imageData) {
      requestBody.image_data = imageData;
    }
    
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    
    // Handle error responses
    if (!response.ok) {
      let errorDetail = 'Unknown error';
      try {
        const errorJson = await response.json();
        errorDetail = errorJson.detail || errorJson.message || JSON.stringify(errorJson);
      } catch (e) {
        // If not JSON, try to get text
        try {
          errorDetail = await response.text();
        } catch (textError) {
          errorDetail = `Status ${response.status}`;
        }
      }
      console.error('API error response:', errorDetail);
      throw new Error(`API error: ${response.status} - ${errorDetail}`);
    }
    
    const data = await response.json();
    console.log('Query response:', data);
    
    // The backend returns a ResponseModel with:
    // - response: The generated text response
    // - inference_time: Time taken for inference
    
    return {
      response: data.response,
      inference_time: data.inference_time,
      // Default confidence if not provided
      confidence: 0.85
    };
  } catch (error) {
    console.error('Error sending query:', error);
    // Rethrow the error to be handled by the component
    throw error;
  }
};

/**
 * Legacy method for backward compatibility
 * Now redirects to sendQuery
 */
export const sendMessage = async (message, customEndpoint, imageContext = null) => {
  console.log('sendMessage is deprecated, using sendQuery instead');
  return sendQuery(message, customEndpoint);
};

// Mock data removed to avoid unused variable warnings
