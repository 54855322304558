import React from 'react';
import styles from './MobileNotice.module.css';

// Professional-looking mobile notice component
const MobileNotice = ({ getAssetPath }) => {
  return (
    <div className={styles.mobileNotice}>
      <div className={styles.mobileNoticeContainer}>
        <div className={styles.mobileNoticeLogo}>
          <svg width="80" height="80" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45" stroke="#DC5029" strokeWidth="2"/>
            <path d="M30 50C45 30 55 30 70 50" stroke="#DC5029" strokeWidth="2"/>
            <path d="M30 50C45 70 55 70 70 50" stroke="#DC5029" strokeWidth="2"/>
          </svg>
        </div>
        <h1>Desktop Application Required</h1>
        <p>Project Moose is a sophisticated medical imaging analysis platform designed for healthcare professionals.</p>
        <div className={styles.mobileNoticeDivider}></div>
        <p>For optimal performance and accurate analysis, please access this application on a desktop or laptop computer.</p>
        <ul className={styles.mobileNoticeRequirements}>
          <li>Recommended screen resolution: 1280×800 or higher</li>
          <li>Chrome, Firefox, or Edge browsers</li>
          <li>Stable internet connection</li>
        </ul>
        <div className={styles.mobileNoticeAction}>
          <p>Please return to this site using a desktop device to access the full functionality of Project Moose.</p>
        </div>
      </div>
    </div>
  );
};

export default MobileNotice;
