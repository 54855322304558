import React from 'react';
import styles from './TabNavigation.module.css'; // Use CSS Modules import

// SVG Icons defined inline for simplicity
const ImageIcon = () => (
  <svg className={styles.tabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="3" y="3" width="18" height="18" rx="2" />
    <circle cx="8.5" cy="8.5" r="1.5" />
    <path d="M21 15l-5-5L5 21" />
  </svg>
);

const DocIcon = () => (
  <svg className={styles.tabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
    <polyline points="14 2 14 8 20 8" />
    <line x1="16" y1="13" x2="8" y2="13" />
    <line x1="16" y1="17" x2="8" y2="17" />
    <line x1="10" y1="9" x2="8" y2="9" />
  </svg>
);

// Advanced Diagnostics Icon
const AdvancedIcon = () => (
  <svg className={styles.tabIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="9" />
    <path d="M12 8v4l3 3" />
    <line x1="8" y1="3" x2="16" y2="3" />
    <line x1="7" y1="21" x2="17" y2="21" />
  </svg>
);

// Default icons mapping
const defaultIcons = {
  'ImageView': ImageIcon,
  'DocumentationView': DocIcon,
  'AdvancedDiagnostics': AdvancedIcon
};

/**
 * TabNavigation component - Renders view switching buttons for the canvas area
 */
export function TabNavigation({ activeTab, setActiveTab, tabs = [] }) {
  // Helper function to generate class names for tab buttons
  const getTabClassName = (tabId) => {
    // Use styles object from CSS Modules
    return `${styles.tabButton} ${activeTab === tabId ? styles.active : ''}`;
  };

  return (
    // Use styles object for container class names
    <div className={styles.tabNavigationContainer}>
      <div className={styles.tabNavigation}>
        {tabs.map(tab => {
          // Use provided icon or default icon if available, otherwise null
          const IconComponent = tab.icon || defaultIcons[tab.id] || (() => null);
          
          return (
            <button
              key={tab.id}
              className={getTabClassName(tab.id)}
              onClick={() => setActiveTab(tab.id)}
            >
              <IconComponent />
              {tab.label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

// It's conventional to also have a default export if this is the main export
export default TabNavigation;