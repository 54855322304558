import React, { useState, useRef, useContext, useEffect } from 'react';
import styles from './LeftPanel.module.css';
import { ViewStateContext } from '../../canvas/CanvasViewManager';

export function LeftPanel({ onImageUpload, onViewInCanvas, onAnalyze, hasImage, imageSrc }) {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [promptType, setPromptType] = useState('default');
  const [advancedConfig, setAdvancedConfig] = useState(null);
  const [advancedDataCompletion, setAdvancedDataCompletion] = useState(0);
  
  // Access the view context to switch views
  const viewContext = useContext(ViewStateContext);
  
  // Check if user has selected an analysis type
  const hasSelectedAnalysisType = promptType !== 'default';
  
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      onImageUpload(e.target.files[0]);
    }
  };
  
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };
  
  const handleDragLeave = () => {
    setIsDragging(false);
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onImageUpload(e.dataTransfer.files[0]);
    }
  };
  
  const handleAnalyzeClick = () => {
    onAnalyze(selectedPrompt, promptType);
  };
  
  const handleExampleClick = (exampleText, type) => {
    setSelectedPrompt(exampleText);
    setPromptType(type);
    
    // Reset advanced config when selecting a different prompt type
    if (type !== 'advanced_diagnostics') {
      setAdvancedConfig(null);
    }
  };
  
  // Handle input from the AdvancedDiagnostics view
  const handleAdvancedInput = (config) => {
    setSelectedPrompt(config.prompt);
    setPromptType(config.promptType);
    setAdvancedConfig(config);
    
    // Calculate completion percentage based on filled fields
    if (config.formData) {
      const filledFields = Object.entries(config.formData)
        .filter(([key, value]) => {
          // Skip arrays that are empty
          if (Array.isArray(value) && value.length === 0) return false;
          // Count fields that have values
          return value && (typeof value === 'string' ? value.trim() !== '' : true);
        })
        .length;
      
      const totalFields = Object.keys(config.formData).length;
      const completionPercentage = Math.round((filledFields / totalFields) * 100);
      setAdvancedDataCompletion(completionPercentage);
    }
  };
  
  // Effect to listen for the custom event from AdvancedDiagnostics
  useEffect(() => {
    const handleAdvancedDiagnosticsSubmit = (event) => {
      handleAdvancedInput(event.detail);
    };
    
    // Get the left panel DOM element
    const leftPanelElement = document.querySelector('.leftPanel');
    if (leftPanelElement) {
      // Add event listener
      leftPanelElement.addEventListener('advancedDiagnosticsSubmit', handleAdvancedDiagnosticsSubmit);
      
      // Cleanup function
      return () => {
        leftPanelElement.removeEventListener('advancedDiagnosticsSubmit', handleAdvancedDiagnosticsSubmit);
      };
    }
  }, []);
  
  // Switch to AdvancedDiagnostics view
  const handleAdvancedClick = () => {
    // Check if viewContext is available
    if (viewContext && viewContext.setActiveView) {
      viewContext.setActiveView('AdvancedDiagnostics');
    }
    
    // Set the type to prevent the "SELECT ANALYSIS TYPE" message
    setPromptType('advanced_diagnostics');
  };

  // Define the structured full report prompt
  const fullReportPrompt = `Analyze this medical image and provide a comprehensive report using the following structure:

  1. OVERVIEW:
     - Describe the type of imaging (X-ray, CT, MRI, ultrasound, photograph, etc.)
     - Comment on image quality and positioning
     - Briefly mention the most significant finding(s)

  2. FINDINGS:
     - List all abnormalities in order of clinical significance
     - For each finding, describe location, size, characteristics and relation to surrounding structures

  3. IMPRESSION:
     - Provide interpretation of findings for each relevant anatomical region
     - Offer differential diagnosis (list most likely diagnosis first)
     - Discuss clinical correlation where appropriate

  4. RECOMMENDATIONS:
     - Suggest appropriate follow-up imaging if needed
     - Recommend additional tests or consultations`;
  
  return (
    <div className={`${styles.leftPanel} leftPanel`}>
      <div className={styles.titleSection}>
        <div className={styles.titleContainer}>
          <h1>WELCOME TO MOOSE RESEARCH PREVIEW!</h1>
          <div className={styles.researchPreview}>DEVELOPED BY MOHANAD TAHA, MD</div>
          <div className={styles.proprietyInfo}>INTERNAL MEDICINE PGY-1</div>
          <div className={styles.proprietyInfo}>CLINICAL ARTIFICIAL INTELLIGENCE</div>
          <div className={styles.proprietyInfo}>CASE WESTERN | UNIVERSITY HOSPITALS</div>
        </div>
      </div>
      
      <div className={styles.contentSection}>
        <div className={styles.uploadSection}>
          <h3>1. Upload Medical Image(s)</h3>
          <div 
            className={`${styles.uploadArea} ${isDragging ? styles.dragover : ''} ${hasImage ? styles.hasThumbnail : ''}`}
            onClick={handleUploadClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {hasImage ? (
              <div className={styles.thumbnailContainer}>
                <img 
                  src={imageSrc || '/placeholder-image.png'} 
                  alt="Uploaded thumbnail" 
                  className={styles.thumbnailImage} 
                />
              </div>
            ) : (
              <p>Drag and drop image(s) here or click to upload</p>
            )}
            <input 
              type="file" 
              ref={fileInputRef}
              accept="image/*" 
              onChange={handleFileChange} 
              hidden
            />
          </div>
          
          {hasImage && (
            <button 
              className={styles.activateBtn}
              onClick={onViewInCanvas}
            >
              VIEW IN CANVAS
            </button>
          )}
        </div>
        
        <div className={styles.questionSection}>
          <h3>2. Select Analysis Type</h3>
          
          <div className={styles.promptExamples}>
            <div 
              className={`${styles.promptButton} ${promptType === "full_report" ? styles.selected : ""}`}
              onClick={() => handleExampleClick(fullReportPrompt, "full_report")}
            >
              Generate a full report
            </div>
            <div 
              className={`${styles.promptButton} ${promptType === "rare_causes" ? styles.selected : ""}`}
              onClick={() => handleExampleClick("What are rare causes or associations for the findings in this image?", "rare_causes")}
            >
              What are rare causes/associations?
            </div>
            <div 
              className={`${styles.promptButton} ${promptType === "advanced_diagnostics" ? styles.selected : ""}`}
              onClick={handleAdvancedClick}
            >
              <div className={styles.promptButtonContent}>
                <span>🦌 Moose Gestalt</span>
                {advancedDataCompletion > 0 && (
                  <span className={styles.completionBadge}>
                    {advancedDataCompletion}%
                  </span>
                )}
              </div>
            </div>
          </div>
          
          {/* Display advanced config summary if available */}
          {advancedConfig && (
            <div className={styles.advancedSummary}>
              <h4>🦌 Moose Gestalt Summary</h4>
              <ul>
                {advancedConfig.formData.patientSummary && (
                  <li>Case: {advancedConfig.formData.patientSummary}</li>
                )}
                {advancedConfig.formData.clinicalQuestion && (
                  <li>Question: {advancedConfig.formData.clinicalQuestion}</li>
                )}
                {advancedConfig.formData.presentationArchetype && (
                  <li>Archetype: {advancedConfig.formData.presentationArchetype.replace('_', ' ')}</li>
                )}
                {advancedConfig.formData.clinicalIntent && advancedConfig.formData.clinicalIntent.length > 0 && (
                  <li>Intent: {advancedConfig.formData.clinicalIntent.length} selected</li>
                )}
                {advancedConfig.formData.diagnosticState && (
                  <li>Diagnostic state: {advancedConfig.formData.diagnosticState}</li>
                )}
                {advancedConfig.formData.critiqueLevel && (
                  <li>Critique level: {advancedConfig.formData.critiqueLevel}</li>
                )}
              </ul>
              <button 
                className={styles.editButton}
                onClick={handleAdvancedClick}
              >
                Edit
              </button>
            </div>
          )}
        </div>
        
        <div className={styles.actionButtonContainer}>
          <button 
            className={`${styles.actionBtn} ${!hasSelectedAnalysisType ? styles.disabled : ''}`}
            onClick={handleAnalyzeClick}
            disabled={!hasImage || !hasSelectedAnalysisType}
            title={!hasImage ? "Upload an image first" : !hasSelectedAnalysisType ? "Select an analysis type first" : ""}
          >
            {hasImage && !hasSelectedAnalysisType ? "SELECT ANALYSIS TYPE" : "ANALYZE"}
          </button>
        </div>
      </div>
    </div>
  );
}

// Named export for better import/export consistency
export default LeftPanel;
